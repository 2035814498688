/* eslint-disable max-lines-per-function */
/* eslint-disable complexity */
import { Injectable } from '@angular/core';
import { ApplicationEventsHandler, ongoingEventsOrchestrationStrategy } from '@cobiro/eda';
import { LocalStorageShopsChangedEvent } from '@app.cobiro.com/cobiro-pro-rewrite/clients';

@Injectable()
export class LocalStorageShopsChangedEventHandler implements ApplicationEventsHandler {
  eventsClasses = [LocalStorageShopsChangedEvent];
  strategy = ongoingEventsOrchestrationStrategy;

  handle([event]: [LocalStorageShopsChangedEvent]): void {}
}
