import { IntegrationInfoDto } from '../secondary/integration-info.dto';
import { IntegrationErrorQuery } from './integration-error.query';

export class IntegrationInfoQuery {
  private constructor(
    public readonly id: string,
    public readonly type: 'owned' | 'switched' | 'non-css',
    public readonly status: string,
    public readonly errors: IntegrationErrorQuery | null,
  ) {}

  static fromDTO(dto: IntegrationInfoDto): IntegrationInfoQuery {
    return new IntegrationInfoQuery(
      dto.id,
      dto.type,
      dto.status,
      dto.errors && dto.errors.code ? IntegrationErrorQuery.fromDTO(dto.errors) : null,
    );
  }
}
