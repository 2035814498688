/* eslint-disable max-lines-per-function */
import { ChangeDetectionStrategy, Component, Inject, Input, OnInit } from '@angular/core';
import {
  IS_TRIAL_PERIOD_QUERY,
  IsTrialPeriodQueryPort,
} from '../../../../../application/ports/primary/setup/is-trial-period.query-port';
import { Observable, firstValueFrom, map } from 'rxjs';
import { ProductQuery } from '../../../../../application/ports/primary/clients/product.query';
import {
  UPGRADES_PLAN_COMMAND,
  UpgradesPlanCommandPort,
} from '../../../../../application/ports/primary/payments/upgrades-plan-client.command-port';
import {
  GETS_SELECTED_CLIENT_QUERY,
  GetsSelectedClientQueryPort,
} from '../../../../../application/ports/primary/clients/gets-selected-client.query-port';
import { ActivatedRoute } from '@angular/router';
import { APPLICATION_BUS, Dispatcher, ApplicationEvent } from '@cobiro/eda';
import { PRODUCT_LIST } from '../../../../../application/ports/primary/clients/product-list.query';
import { ManageClientClickedEvent } from '../../../../../application/events/manage-client-clicked.event';

@Component({
  selector: 'lib-cobiro-pro-activation-widget',
  templateUrl: './activation-widget.component.html',
  styleUrls: ['./activation-widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActivationWidgetComponent implements OnInit {
  @Input() productInfo: ProductQuery;

  isTrial$: Observable<{ value: boolean }>;

  constructor(
    @Inject(IS_TRIAL_PERIOD_QUERY)
    private readonly _isTrialPeriodQueryPort: IsTrialPeriodQueryPort,
    @Inject(UPGRADES_PLAN_COMMAND)
    private readonly _upgradesPlanCommand: UpgradesPlanCommandPort,
    @Inject(GETS_SELECTED_CLIENT_QUERY)
    private readonly _getSelectedClientQuery: GetsSelectedClientQueryPort,
    @Inject(APPLICATION_BUS)
    private readonly _applicationBus: Dispatcher<ApplicationEvent>,
    private readonly _route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.isTrial$ = this._isTrialPeriodQueryPort
      .isTrial(this.productInfo.name)
      .pipe(map(res => ({ value: res })));
  }

  async onUpgradePlanClicked(): Promise<void> {
    const workspaces = this._route.snapshot.data['workspaces'];
    const client = await firstValueFrom(this._getSelectedClientQuery.getSelectedClient());

    this.productInfo.name === PRODUCT_LIST.labelManager
      ? this._applicationBus.dispatch(
          new ManageClientClickedEvent(
            client.id,
            this.productInfo.name,
            client.workspaceId,
            workspaces,
          ),
        )
      : await firstValueFrom(
          this._upgradesPlanCommand.upgradePlan({
            clientId: client.id,
            siteId: client.siteId,
            companyName: client.companyName,
            productName: this.productInfo.name,
          }),
        );
  }
}
