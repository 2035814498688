import { InjectionToken } from '@angular/core';
import { LabelManagerRecommendationQuery } from './label-manager-recomendation.query';

export const DONE_LM_RECOMMENDATION_QUERY = new InjectionToken<DoneLMRecommendationQueryPort>(
  'DONE_LM_RECOMMENDATION_QUERY',
);

export interface DoneLMRecommendationQueryPort {
  doneLmRecommendation(value: LabelManagerRecommendationQuery): void;
}
