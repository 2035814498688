/* eslint-disable complexity */
/* eslint-disable max-lines-per-function */
import { Inject, Injectable } from '@angular/core';
import { STORAGE } from '@app.cobiro.com/core/storage';
import { LabelManagerRecommendationQuery } from '../../ports/primary/label-manager-recomendation.query';
import { LabelManagerCloseRecommendationEvent } from '@app.cobiro.com/core/events';
import { APPLICATION_BUS, ApplicationBus } from '@cobiro/eda';
import {
  BehaviorSubject,
  Observable,
  combineLatest,
  distinctUntilChanged,
  filter,
  map,
  of,
  tap,
} from 'rxjs';
import { LabelManagerReportQuery } from '../../ports/primary/label-manager-report.query';
import { LabelManagerConfigDto } from '../../ports/secondary/label-manager-config.dto';
import { LABEL_MANAGER_CONFIG_DTO_STUB } from '../../ports/secondary/label-manager-config.dto.stub';
import { ClosesLMRecommendationQueryPort } from '../../ports/primary/closes-lm-recommendation.query-port';
import { GetsCurrentLMRecommendationQueryPort } from '../../ports/primary/gets-current-lm-recommendation.query-port';
import { SetsLabelManagerConfigForRecommendationCommandPort } from '../../ports/primary/sets-label-manager-config-for-recommendation.command-port';
import { SetsLabelManagerReportForRecommendationCommandPort } from '../../ports/primary/sets-label-manager-report-for-recommendation.command-port';
import { IntegrationIdChangedEvent } from '@app.cobiro.com/cobiro-pro-rewrite/cobiro-css';
import { IsRecommendationListEmptyCommandPort } from '../../ports/primary/is-recommendations-list-empty.command-port';
import { DoneLMRecommendationQueryPort } from '../../ports/primary/done-lm-recommendation.query-port';

@Injectable()
export class LabelManagerRecommendationState
  implements
    ClosesLMRecommendationQueryPort,
    GetsCurrentLMRecommendationQueryPort,
    SetsLabelManagerConfigForRecommendationCommandPort,
    SetsLabelManagerReportForRecommendationCommandPort,
    IsRecommendationListEmptyCommandPort,
    DoneLMRecommendationQueryPort
{
  private _integrationId$: BehaviorSubject<string | null> = new BehaviorSubject(null);
  private _configAttributes$: BehaviorSubject<LabelManagerConfigDto> =
    new BehaviorSubject<LabelManagerConfigDto>(LABEL_MANAGER_CONFIG_DTO_STUB);
  private _currentLMReport$: BehaviorSubject<LabelManagerReportQuery | null> = new BehaviorSubject(
    null,
  );
  private _recommendations$: BehaviorSubject<LabelManagerRecommendationQuery[] | null> =
    new BehaviorSubject(null);

  constructor(
    @Inject(STORAGE)
    private readonly _storage: Storage,
    @Inject(APPLICATION_BUS)
    private readonly _applicationBus: ApplicationBus,
  ) {
    this._applicationBus
      .on(IntegrationIdChangedEvent)
      .pipe(
        tap((event: IntegrationIdChangedEvent) => this._integrationId$.next(event.integration.id)),
      )
      .subscribe();
  }
  isEmpty(): Observable<boolean> {
    return this._recommendations$.asObservable().pipe(
      map(res => res && res.length > 0),
      distinctUntilChanged(),
    );
  }

  setReport(report: LabelManagerReportQuery): Observable<boolean> {
    this._currentLMReport$.next(report);
    return of(true);
  }

  setConfig(config: LabelManagerConfigDto): Observable<boolean> {
    this._configAttributes$.next(config);
    return of(true);
  }

  closeLmRecommendation(value: LabelManagerRecommendationQuery): void {
    const currentStorageValue = this._storage.getItem('cobiro-pro-lm-recommendation');
    const currentValue = {
      id: value.id,
      integrationId: value.integrationId,
      userId: value.userId,
      timestamp: Date.now(),
      analysisTimestamp: value.analysisTimestamp,
    };
    const recommendations = this._recommendations$.getValue();
    const arr = Array.of(
      ...(currentStorageValue
        ? [...JSON.parse(currentStorageValue), currentValue]
        : [currentValue]),
    );
    this._applicationBus.dispatch(
      new LabelManagerCloseRecommendationEvent(
        value.id,
        value.integrationId,
        value.userId,
        value.timestamp,
        value.analysisTimestamp.toISOString(),
      ),
    );
    this._recommendations$.next(recommendations.filter(item => item.id !== value.id));
    this._storage.setItem('cobiro-pro-lm-recommendation', JSON.stringify(arr));
  }

  doneLmRecommendation(value: LabelManagerRecommendationQuery): void {
    const currentStorageValue = this._storage.getItem('cobiro-pro-lm-recommendation');
    const currentValue = {
      id: value.id,
      integrationId: value.integrationId,
      userId: value.userId,
      timestamp: null,
      analysisTimestamp: value.analysisTimestamp,
    };
    const recommendations = this._recommendations$.getValue();
    const arr = Array.of(
      ...(currentStorageValue
        ? [...JSON.parse(currentStorageValue), currentValue]
        : [currentValue]),
    );
    this._applicationBus.dispatch(
      new LabelManagerCloseRecommendationEvent(
        value.id,
        value.integrationId,
        value.userId,
        value.timestamp,
        value.analysisTimestamp.toISOString(),
      ),
    );
    this._recommendations$.next(recommendations.filter(item => item.id !== value.id));
    this._storage.setItem('cobiro-pro-lm-recommendation', JSON.stringify(arr));
  }

  getCurrentLMRecommendation(
    isSimulation: boolean,
  ): Observable<LabelManagerRecommendationQuery[] | null> {
    const currentStorageValue = this._cleanUpStorageLMRecommendation();
    return combineLatest([
      this._currentLMReport$.asObservable().pipe(filter(Boolean)),
      this._configAttributes$.asObservable(),
    ]).pipe(
      map(([res, config]: [LabelManagerReportQuery, LabelManagerConfigDto]) => {
        if (isSimulation) {
          config = LABEL_MANAGER_CONFIG_DTO_STUB;
        }
        if (res === null || config === null || res.data.length === 0) {
          return null;
        }
        const mappedResults = res.data[0].dataLabels.reduce(
          (acc, key, i) => Object.assign(acc, { [key]: res.data[0].chartSeries[0].data[i] }),
          {},
        );
        const mappedResultsPrevious =
          res.data.length > 1
            ? res.data[1].dataLabels.reduce(
                (acc, key, i) => Object.assign(acc, { [key]: res.data[0].chartSeries[0].data[i] }),
                {},
              )
            : null;

        const arr = Array.of(
          ...this._getAlertBreakevenRoasTooHigh(
            mappedResults['overIndex'],
            mappedResults['index'],
            mappedResults['nearIndex'],
            config.breakEvenROAS,
            res.data[0].date,
          ),
          ...this._getAlertEnoughProducts(
            mappedResults['overIndex'],
            mappedResults['index'],
            mappedResults['nearIndex'],
            res.data[0].date,
          ),
          ...this._getAlertLookbackWindowTooSmall(
            mappedResults['overIndex'],
            mappedResults['index'],
            mappedResults['nearIndex'],
            config.lookBackWindow,
            res.data[0].date,
          ),
          ...this._getAlertLookbackWindowTooSmallSleepers(
            mappedResults['noIndex'],
            res.data[0].totalProducts,
            config.lookBackWindow,
            res.data[0].date,
          ),
          ...this._getInsightTopSegmentsNeedMoreBudget(
            res.data.length > 0,
            res.data[0].segments.conversionsValue['overIndex'],
            res.data[0].segments.conversionsValue['index'],
            res.data[0].segments.conversionsValue['nearIndex'],
            res.data[0].segments.conversionsValue['total'],
            res.data[0].segments.cost['overIndex'],
            res.data[0].segments.cost['index'],
            res.data[0].segments.cost['nearIndex'],
            res.data[0].segments.cost['total'],
            config.lookBackWindow,
            res.data[0].date,
          ),
          ...this._getInsightWastersSpendsTooMuch(
            res.data.length > 0,
            res.data[0].segments.conversionsValue['underIndex'],
            res.data[0].segments.conversionsValue['total'],
            res.data[0].segments.cost['underIndex'],
            res.data[0].segments.cost['total'],
            res.data[0].date,
          ),
          ...this._getInsightSleepersSpendsTooMuch(
            res.data.length > 0,
            mappedResults['overIndex'],
            mappedResults['index'],
            mappedResults['nearIndex'],
            res.data[0].segments.conversionsValue['noIndex'],
            res.data[0].segments.conversionsValue['total'],
            res.data[0].segments.cost['noIndex'],
            res.data[0].segments.cost['total'],
            config.lookBackWindow,
            res.data[0].date,
          ),
          ...this._getInsightWinnersAreGrowing(
            res.data.length > 0,
            mappedResults['overIndex'],
            mappedResultsPrevious ? mappedResultsPrevious['overIndex'] : null,
            res.data[0].date,
          ),
          ...this._getInsightProspectsAreGrowing(
            res.data.length > 0,
            mappedResults['index'],
            mappedResultsPrevious ? mappedResultsPrevious['index'] : null,
            res.data[0].date,
          ),
          ...this._getInsightJumpersAreGrowing(
            res.data.length > 0,
            mappedResults['nearIndex'],
            mappedResultsPrevious ? mappedResultsPrevious['nearIndex'] : null,
            res.data[0].date,
          ),
          ...this._getInsightSleepersAreGrowing(
            res.data.length > 0,
            mappedResults['noIndex'],
            mappedResultsPrevious ? mappedResultsPrevious['noIndex'] : null,
            res.data[0].date,
          ),
          ...this._getInsightWastersAreGrowing(
            res.data.length > 0,
            mappedResults['underIndex'],
            mappedResultsPrevious ? mappedResultsPrevious['underIndex'] : null,
            res.data[0].date,
          ),
          ...this._getWarningProductQuantityIsNotOptimal(
            mappedResults['noIndex'],
            res.data[0].totalProducts,
            res.data[0].date,
          ),
        );
        const filteredArr = arr.filter(
          item =>
            !currentStorageValue.some(
              i =>
                i.id === item.id &&
                i.integrationId === item.integrationId &&
                i.userId === item.userId &&
                new Date(i.analysisTimestamp).getTime() === item.analysisTimestamp.getTime(),
            ),
        );
        this._recommendations$.next(filteredArr);
        return filteredArr;
      }),
    );
  }

  private _cleanUpStorageLMRecommendation(): Pick<
    LabelManagerRecommendationQuery,
    'id' | 'integrationId' | 'userId' | 'timestamp' | 'analysisTimestamp'
  >[] {
    const date = Date.now();
    // const expirationPeriod = 30 * 24 * 60 * 60 * 1000;
    const expirationPeriod = 1000;
    const arr = this._storage.getItem('cobiro-pro-lm-recommendation')
      ? JSON.parse(this._storage.getItem('cobiro-pro-lm-recommendation'))
      : [];
    const cleanedArr = arr.filter(
      (
        item: Pick<
          LabelManagerRecommendationQuery,
          'id' | 'integrationId' | 'userId' | 'timestamp' | 'analysisTimestamp'
        >,
      ) => (item.timestamp ? date - item.timestamp < expirationPeriod : true),
    );
    this._storage.setItem('cobiro-pro-lm-recommendation', JSON.stringify(cleanedArr));
    return cleanedArr;
  }

  private _getAlertBreakevenRoasTooHigh(
    overIndex: number,
    index: number,
    nearIndex: number,
    breakEvenROAS: number,
    date: Date,
  ): LabelManagerRecommendationQuery[] {
    const currentUserId = JSON.parse(this._storage.getItem('lib-user-latest-login-redirect'));
    return overIndex + index < nearIndex
      ? [
          {
            id: 'breakevenROAS_too_high',
            title: 'cobiro_pro_label_manager_recommendation_breakevenROAS_too_high_title',
            type: 'alert' as const,
            description:
              'cobiro_pro_label_manager_recommendation_breakevenROAS_too_high_description',
            descriptionValue: (breakEvenROAS * 0.75).toFixed(0),
            userId: currentUserId.userId,
            integrationId: this._integrationId$.getValue(),
            analysisTimestamp: new Date(date),
          },
        ]
      : [];
  }

  private _getAlertEnoughProducts(
    overIndex: number,
    index: number,
    nearIndex: number,
    date: Date,
  ): LabelManagerRecommendationQuery[] {
    const currentUserId = JSON.parse(this._storage.getItem('lib-user-latest-login-redirect'));
    return overIndex + index + nearIndex > 300
      ? [
          {
            id: 'enough_products',
            title: 'cobiro_pro_label_manager_recommendation_enough_products_title',
            type: 'alert' as const,
            description: 'cobiro_pro_label_manager_recommendation_enough_products_description',
            descriptionValue: '',
            userId: currentUserId.userId,
            integrationId: this._integrationId$.getValue(),
            analysisTimestamp: new Date(date),
          },
        ]
      : [];
  }

  private _getAlertLookbackWindowTooSmall(
    overIndex: number,
    index: number,
    nearIndex: number,
    lookBackWindow: number,
    date: Date,
  ): LabelManagerRecommendationQuery[] {
    const currentUserId = JSON.parse(this._storage.getItem('lib-user-latest-login-redirect'));

    return overIndex + index + nearIndex < 300
      ? [
          {
            id: 'lookback_window_too_small',
            title: 'cobiro_pro_label_manager_recommendation_lookback_window_too_small_title',
            type: 'alert' as const,
            description:
              'cobiro_pro_label_manager_recommendation_lookback_window_too_small_description',
            descriptionValue: lookBackWindow * 1.5 > 90 ? '90' : (lookBackWindow * 1.5).toFixed(0),
            userId: currentUserId.userId,
            integrationId: this._integrationId$.getValue(),
            analysisTimestamp: new Date(date),
          },
        ]
      : [];
  }

  private _getAlertLookbackWindowTooSmallSleepers(
    noIndex: number,
    totalProducts: number,
    lookBackWindow: number,
    date: Date,
  ): LabelManagerRecommendationQuery[] {
    const currentUserId = JSON.parse(this._storage.getItem('lib-user-latest-login-redirect'));

    return noIndex > totalProducts * 0.85
      ? [
          {
            id: 'lookback_window_too_small_sleepers',
            title:
              'cobiro_pro_label_manager_recommendation_lookback_window_too_small_sleepers_title',
            type: 'alert' as const,
            description:
              'cobiro_pro_label_manager_recommendation_lookback_window_too_small_sleepers_description',
            descriptionValue: lookBackWindow * 1.5 > 90 ? '90' : (lookBackWindow * 1.5).toFixed(0),
            userId: currentUserId.userId,
            integrationId: this._integrationId$.getValue(),
            analysisTimestamp: new Date(date),
          },
        ]
      : [];
  }

  private _getWarningProductQuantityIsNotOptimal(
    noIndex: number,
    totalProducts: number,
    date: Date,
  ): LabelManagerRecommendationQuery[] {
    const currentUserId = JSON.parse(this._storage.getItem('lib-user-latest-login-redirect'));

    return noIndex > totalProducts * 0.85
      ? [
          {
            id: 'product_quantity_is_not_optimal',
            title: 'cobiro_pro_label_manager_recommendation_product_quantity_is_not_optimal_title',
            type: 'warning' as const,
            description:
              'cobiro_pro_label_manager_recommendation_product_quantity_is_not_optimal_description',
            userId: currentUserId.userId,
            integrationId: this._integrationId$.getValue(),
            analysisTimestamp: new Date(date),
          },
        ]
      : [];
  }

  private _getInsightTopSegmentsNeedMoreBudget(
    hasData: boolean,
    overIndexConvValue: number,
    indexConvValue: number,
    nearIndexConvValue: number,
    totalConvValue: number,
    overIndexCost: number,
    indexCost: number,
    nearIndexCost: number,
    totalCost: number,
    lookbackWindow: number,
    date: Date,
  ) {
    const currentUserId = JSON.parse(this._storage.getItem('lib-user-latest-login-redirect'));

    return hasData &&
      (overIndexConvValue + indexConvValue + nearIndexConvValue) / totalConvValue >
        (overIndexCost + indexCost + nearIndexCost) / totalCost
      ? [
          {
            id: 'top_segments_need_more_budget',
            title: 'cobiro_pro_label_manager_recommendation_top_segments_need_more_budget_title',
            type: 'insight' as const,
            description:
              'cobiro_pro_label_manager_recommendation_top_segments_need_more_budget_description',
            descriptionValue: [
              (
                ((overIndexConvValue + indexConvValue + nearIndexConvValue) / totalConvValue) *
                100
              ).toFixed(2),
              (((overIndexCost + indexCost + nearIndexCost) / totalCost) * 100).toFixed(2),
              (((overIndexCost + indexCost + nearIndexCost) / lookbackWindow) * 1.5).toFixed(0),
            ],
            userId: currentUserId.userId,
            integrationId: this._integrationId$.getValue(),
            analysisTimestamp: new Date(date),
          },
        ]
      : [];
  }

  private _getInsightWastersSpendsTooMuch(
    hasData: boolean,
    underIndexConvValue: number,
    totalConvValue: number,
    underIndexCosts: number,
    totalCost: number,
    date: Date,
  ) {
    const currentUserId = JSON.parse(this._storage.getItem('lib-user-latest-login-redirect'));

    return hasData
      ? [
          {
            id: 'wasters_spends_too_much',
            title: 'cobiro_pro_label_manager_recommendation_wasters_spends_too_much_title',
            type: 'insight' as const,
            description:
              'cobiro_pro_label_manager_recommendation_wasters_spends_too_much_description',
            descriptionValue: [
              ((underIndexConvValue / totalConvValue) * 100).toFixed(2),
              ((underIndexCosts / totalCost) * 100).toFixed(2),
            ],
            userId: currentUserId.userId,
            integrationId: this._integrationId$.getValue(),
            analysisTimestamp: new Date(date),
          },
        ]
      : [];
  }

  private _getInsightSleepersSpendsTooMuch(
    hasData: boolean,
    overIndex: number,
    index: number,
    nearIndex: number,
    noIndexConvValue: number,
    totalConvValue: number,
    noIndexCost: number,
    totalCost: number,
    lookbackWindow: number,
    date: Date,
  ) {
    const currentUserId = JSON.parse(this._storage.getItem('lib-user-latest-login-redirect'));

    return hasData && overIndex + index + nearIndex > 300
      ? [
          {
            id: 'sleepers_spends_too_much',
            title: 'cobiro_pro_label_manager_recommendation_sleepers_spends_too_much_title',
            type: 'insight' as const,
            description:
              'cobiro_pro_label_manager_recommendation_sleepers_spends_too_much_description',
            descriptionValue: [
              ((noIndexConvValue / totalConvValue) * 100).toFixed(2),
              ((noIndexCost / totalCost) * 100).toFixed(2),
              (noIndexCost / lookbackWindow).toFixed(0),
            ],
            userId: currentUserId.userId,
            integrationId: this._integrationId$.getValue(),
            analysisTimestamp: new Date(date),
          },
        ]
      : [];
  }

  private _getInsightWinnersAreGrowing(
    hasData: boolean,
    currentOverIndex: number,
    previousOverIndex: number,
    date: Date,
  ): LabelManagerRecommendationQuery[] {
    const currentUserId = JSON.parse(this._storage.getItem('lib-user-latest-login-redirect'));

    return hasData && currentOverIndex / previousOverIndex / 100 - 1 > 1
      ? [
          {
            id: 'winners_are_growing',
            title: 'cobiro_pro_label_manager_recommendation_winners_are_growing_title',
            type: 'insight' as const,
            description: 'cobiro_pro_label_manager_recommendation_winners_are_growing_description',
            descriptionValue: [
              (currentOverIndex - previousOverIndex).toFixed(2),
              (currentOverIndex / previousOverIndex / 100 - 1).toFixed(2),
            ],
            userId: currentUserId.userId,
            integrationId: this._integrationId$.getValue(),
            analysisTimestamp: new Date(date),
          },
        ]
      : [];
  }

  private _getInsightProspectsAreGrowing(
    hasData: boolean,
    currentIndex: number,
    previousIndex: number,
    date: Date,
  ): LabelManagerRecommendationQuery[] {
    const currentUserId = JSON.parse(this._storage.getItem('lib-user-latest-login-redirect'));

    return hasData && currentIndex / previousIndex / 100 - 1 > 1
      ? [
          {
            id: 'prospects_are_growing',
            title: 'cobiro_pro_label_manager_recommendation_prospects_are_growing_title',
            type: 'insight' as const,
            description:
              'cobiro_pro_label_manager_recommendation_prospects_are_growing_description',
            descriptionValue: [
              (currentIndex - previousIndex).toFixed(2),
              (currentIndex / previousIndex / 100 - 1).toFixed(2),
            ],
            userId: currentUserId.userId,
            integrationId: this._integrationId$.getValue(),
            analysisTimestamp: new Date(date),
          },
        ]
      : [];
  }

  private _getInsightJumpersAreGrowing(
    hasData: boolean,
    currentNearIndex: number,
    previousNearIndex: number,
    date: Date,
  ): LabelManagerRecommendationQuery[] {
    const currentUserId = JSON.parse(this._storage.getItem('lib-user-latest-login-redirect'));

    return hasData && currentNearIndex / previousNearIndex / 100 - 1 > 1
      ? [
          {
            id: 'jumpers_are_growing',
            title: 'cobiro_pro_label_manager_recommendation_jumpers_are_growing_title',
            type: 'insight' as const,
            description: 'cobiro_pro_label_manager_recommendation_jumpers_are_growing_description',
            descriptionValue: [
              (currentNearIndex - previousNearIndex).toFixed(2),
              (currentNearIndex / previousNearIndex / 100 - 1).toFixed(2),
            ],
            userId: currentUserId.userId,
            integrationId: this._integrationId$.getValue(),
            analysisTimestamp: new Date(date),
          },
        ]
      : [];
  }

  private _getInsightSleepersAreGrowing(
    hasData: boolean,
    currentNoIndex: number,
    previousNoIndex: number,
    date: Date,
  ): LabelManagerRecommendationQuery[] {
    const currentUserId = JSON.parse(this._storage.getItem('lib-user-latest-login-redirect'));

    return hasData && currentNoIndex / previousNoIndex / 100 - 1 > 2
      ? [
          {
            id: 'sleepers_are_growing',
            title: 'cobiro_pro_label_manager_recommendation_sleepers_are_growing_title',
            type: 'insight' as const,
            description: 'cobiro_pro_label_manager_recommendation_sleepers_are_growing_description',
            userId: currentUserId.userId,
            integrationId: this._integrationId$.getValue(),
            analysisTimestamp: new Date(date),
          },
        ]
      : [];
  }

  private _getInsightWastersAreGrowing(
    hasData: boolean,
    currentUnderIndex: number,
    previousUnderIndex: number,
    date: Date,
  ): LabelManagerRecommendationQuery[] {
    const currentUserId = JSON.parse(this._storage.getItem('lib-user-latest-login-redirect'));

    return hasData && currentUnderIndex / previousUnderIndex / 100 - 1 > 2
      ? [
          {
            id: 'wasters_are_growing',
            title: 'cobiro_pro_label_manager_recommendation_wasters_are_growing_title',
            type: 'insight' as const,
            description: 'cobiro_pro_label_manager_recommendation_wasters_are_growing_description',
            userId: currentUserId.userId,
            integrationId: this._integrationId$.getValue(),
            analysisTimestamp: new Date(date),
          },
        ]
      : [];
  }
}
