<ng-container *huiLoaderContainer="app$ | async as app">
  <section
    *ngIf="{ value: isTrial$ | async } as isTrial"
    class="d-flex flex-column flex-sm-row align-items-center cs-mb-15 cs-mb-40-md cs-mh-10"
  >
    <img class="cs-width-60-xs cs-height-60-xs" [src]="app.icon" />
    <article class="flex-1 d-flex flex-column justify-content-center cs-ml-30 cs-mb-10 cs-mb-0-sm">
      <h1 class="cs-mt-0 cs-title-3 text-center text-sm-left">
        {{ app.name | language }}
      </h1>
      <p class="cs-text-2 text-center text-sm-left">{{ app.subtitle | language }}</p>
    </article>
    <div>
      <button
        class="cs-btn cs-btn-primary"
        data-selector="store-details-button"
        [class.is-loading]="buttonLoading"
        [libFeatureId]="app.identifier.toUpperCase() + '_START'"
        (click)="runAdditionalAppFlowBeforeStart()"
      >
        {{
          (isTrial.value ? 'cobiro_pro_label_manager_get_simulation_btn' : '_subscribe') | language
        }}
      </button>
    </div>
  </section>
  <!--   TODO: Double border for Google CSS image - fix   -->
  <ng-container *ngIf="{ value: showSimulationBlock$ | async } as showSimulationBlock">
    <ng-container *huiLoaderContainer="showSimulationBlock.value !== null">
      <ng-container *ngIf="showSimulationBlock.value; else noMarketingPreferences">
        <div
          class="cs-mv-10 ml-auto mr-auto w-100 position-relative align-items-stretch d-flex flex-column flex-sm-row flex-lg-row"
        >
          <section
            *libFeatureFlags="['label-manager-simulation']; else noMarketingPreferences"
            class="cs-card d-flex flex-1 cs-position-relative-xs cs-mb-20 cs-mb-40-md cs-mh-10 cs-min-height-625-xs align-items-center justify-content-center"
          >
            <lib-label-manager-results
              [isSimulation]="true"
              [defaultValues]="emptyDataset"
              class="w-100"
            ></lib-label-manager-results>
            <div
              *ngIf="(showBlur$ | async) === true || (showBlur$ | async) === null"
              class="simulation-container d-flex flex-column justify-content-center align-items-center cs-position-absolute-xs cs-top-0 cs-bottom-0 w-100 h-100"
            >
              <div class="cs-emoji-circle cs-emoji-circle-primary cs-mt-5">📊</div>

              <h4 class="cs-title-4 cs-mt-20">
                {{ 'cobiro_pro_label_manager_get_simulation_title' | language }}
              </h4>
              <p class="cs-text-1 cs-mt-10 cs-mb-40 text-center">
                {{ 'cobiro_pro_label_manager_get_simulation_description' | language }}
              </p>
              <button
                (click)="onGetSimulationButtonClicked()"
                class="cs-btn cs-btn-primary cs-ml-10 cs-min-width-150-xs cs-max-width-200-xs"
                data-selector="cobiro-pro-simulation-lm-btn"
              >
                {{ 'cobiro_pro_label_manager_preview_results_btn' | language }}
              </button>
            </div>
          </section>
          <aside
            class="d-flex flex-grow flex-column overflow-y-auto cs-max-height-625-xs cs-max-width-100-pc-xs cs-max-width-360-lg cs-mt-20 cs-mt-0-sm"
          >
            <div
              class="simulation-container d-flex flex-column justify-content-center align-items-center cs-position-absolute-xs cs-max-height-625-xs cs-max-width-100-pc-xs cs-max-width-360-lg cs-ph-20 w-100 h-100"
            >
              <div class="cs-emoji-circle cs-emoji-circle-primary cs-mt-5">📊</div>

              <h4 class="cs-title-4 text-center cs-mt-20">
                {{ 'cobiro_pro_label_manager_subscribe_recommendation_title' | language }}
              </h4>
              <p class="cs-text-1 cs-mt-10 cs-mb-40 text-center">
                {{ 'cobiro_pro_label_manager_subscribe_recommendation_description' | language }}
              </p>
              <button
                (click)="runAdditionalAppFlowBeforeStart()"
                class="cs-btn cs-btn-primary cs-ml-10 cs-min-width-150-xs cs-max-width-200-xs"
                data-selector="cobiro-pro-subscribe-btn"
              >
                {{ '_subscribe' | language }}
              </button>
            </div>
            <lib-cobiro-label-manager-recommendations
              [isSimulation]="true"
            ></lib-cobiro-label-manager-recommendations>
          </aside>
        </div>
      </ng-container>
    </ng-container>
  </ng-container>

  <ng-template #noMarketingPreferences>
    <section class="cs-mb-20 cs-mb-40-md cs-mh-10" *ngIf="app.image">
      <img
        class="d-block d-md-none content-loading-image-height w-100 br-10 cs-b-1 cs-b-shade-15"
        [src]="app.image.mobile"
        alt="store app preview"
      />
      <img
        class="d-none d-md-block content-loading-image-height w-100 br-10 cs-b-1 cs-b-shade-15"
        [src]="app.image.desktop"
        alt="store app preview"
      />
    </section>
  </ng-template>
  <section class="flex-column-reverse flex-md-row d-flex justify-content-between cs-mh-10">
    <!--    TODO: Styling this part is impossible with FE-based CSS - tempalte is hard-coded on BE by app.content    -->
    <article
      class="store-app-details-content cs-max-width-625-xs w-100 cs-mr-30"
      [innerHTML]="(app.content | language) || ''"
    ></article>
    <aside
      class="store-app-details-sidebar flex-column-reverse flex-md-column d-flex w-100 cs-max-width-360-xs"
    >
      <section
        class="store-app-details-sidebar-endorsements cs-mb-20 cs-mb-40-md"
        *ngIf="app.endorsements?.length"
      >
        <article
          class="cs-p-15 cs-p-20-md d-block d-flex align-items-md-start cs-b-1 cs-b-shade-10 br-10"
          *ngFor="let endorsement of app.endorsements"
        >
          <img
            class="cs-width-40-cs cs-height-40-xs cs-mr-5 cs-mr-20-md"
            [src]="endorsement.icon"
          />
          <span class="flex-1">
            <h5 class="cs-title-8 cs-mt-0 cs-mr-0 cs-mb-5 cs-ml-0">
              {{ endorsement.title | language }}
            </h5>
            <p class="cs-text-2 cs-m-0">
              {{ endorsement.description | language }}
            </p>
            <div *ngIf="endorsement.additionalImages?.length > 0" class="d-flex flex-wrap cs-mt-20">
              <img
                *ngFor="let image of endorsement.additionalImages"
                [src]="image"
                class="cs-height-32-xs w-auto cs-b-1 cs-b-shade-6 cs-mr-5 cs-mb-5"
              />
            </div>
          </span>
        </article>
      </section>
      <section
        class="store-app-details-sidebar-info cs-mb-20 cs-mb-40-md w-100"
        [innerHTML]="(app.additionalContent | language) || ''"
      ></section>
    </aside>
  </section>
</ng-container>
