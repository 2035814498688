/* eslint-disable max-lines-per-function */
import { Injectable } from '@angular/core';
import { GatewayClient } from '@app.cobiro.com/common/gateway';
import { HasDataCollection } from '@cobiro/jsonapi';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { GetsMemberWorkspacesDtoPort } from '../../../../application/ports/secondary/dto/workspaces/gets-member-workspaces.dto-port';
import { WorkspaceDto } from '../../../../application/ports/secondary/dto/workspaces/workspace.dto';

interface WorkspaceAttributes {
  name: string;
  counter: number;
  avatar: string;
  clientCounter: number;
  createdAt: string;
}

@Injectable()
export class HttpWorkspacesService implements GetsMemberWorkspacesDtoPort {
  constructor(private readonly _client: GatewayClient) {}

  getsMembersWorkspace(teamId: string): Observable<WorkspaceDto[]> {
    return this._client
      .get<HasDataCollection<WorkspaceAttributes>>(`v1/sites/teams/${teamId}/member-workspaces`)
      .pipe(
        map(dataCollection => {
          const userWorkspacesIds: string[] = [];
          const userWorkspaces = dataCollection.data.map(data => {
            userWorkspacesIds.push(data.id);

            return {
              id: data.id,
              name: data.attributes.name,
              membersCount: data.attributes.counter,
              clientCount: data.attributes.clientCounter,
              avatar: data.attributes.avatar,
              createdAt: data.attributes.createdAt,
            };
          });

          return [...new Set(userWorkspacesIds)]
            .map(userWorkspaceId => userWorkspaces.find(team => team.id === userWorkspaceId))
            .filter(Boolean);
        }),
      );
  }
}
