import { NgModule } from '@angular/core';
import { ClientModalsModule } from '../../../adapters/primary/ui/modals/client-modals.module';
import { ClientsListChangedEventDispatcher } from '../../../adapters/secondary/dispatchers/clients-list-changed.event-dispatcher';
import { ADD_CLIENT_QUERY } from '../../ports/primary/clients/add-client.query-port';
import { CANCEL_SUBSCRIPTION_CSS_COMMAND } from '../../ports/primary/clients/cancel-subscription-css-client.command-port';
import { CANCEL_SUBSCRIPTION_LM_COMMAND } from '../../ports/primary/clients/cancel-subscription-lm-client.command-port';
import { CREATE_CLIENT_COMMAND } from '../../ports/primary/clients/create-client-command.port';
import { DELETES_CLIENT_COMMAND } from '../../ports/primary/clients/deletes-client.command-port';
import { EDIT_CLIENT_COMMAND } from '../../ports/primary/clients/edit-client-command.port';
import { GETS_CANCEL_REASON_QUERY } from '../../ports/primary/clients/gets-cancel-reason.query-port';
import { GETS_EDIT_CLIENT_QUERY } from '../../ports/primary/clients/gets-edit-client-query.port';
import { GETS_PRODUCT_INFO_QUERY } from '../../ports/primary/clients/gets-product-info.query-port';
import { GETS_SELECTED_CLIENT_QUERY } from '../../ports/primary/clients/gets-selected-client.query-port';
import { SETS_SELECTED_CLIENT_COMMAND } from '../../ports/primary/clients/sets-selected-client-command.port';
import { CLIENTS_LIST_CHANGED_DISPATCHER } from '../../ports/secondary/dispatchers/clients-list-changed.dispatcher-port';
import { ClientsState } from './clients.state';
import { GETS_PRODUCT_LIST_QUERY } from '../../ports/primary/clients/gets-product-list.query';
import { EDIT_CLIENT_NAME_COMMAND } from '../../ports/primary/clients/edit-client-name-command.port';

@NgModule({
  imports: [ClientModalsModule],
  providers: [
    ClientsState,
    { provide: ADD_CLIENT_QUERY, useExisting: ClientsState },
    { provide: EDIT_CLIENT_COMMAND, useExisting: ClientsState },
    { provide: GETS_EDIT_CLIENT_QUERY, useExisting: ClientsState },
    { provide: CREATE_CLIENT_COMMAND, useExisting: ClientsState },
    { provide: DELETES_CLIENT_COMMAND, useExisting: ClientsState },
    { provide: CANCEL_SUBSCRIPTION_CSS_COMMAND, useExisting: ClientsState },
    { provide: CANCEL_SUBSCRIPTION_LM_COMMAND, useExisting: ClientsState },
    { provide: GETS_CANCEL_REASON_QUERY, useExisting: ClientsState },
    { provide: CLIENTS_LIST_CHANGED_DISPATCHER, useClass: ClientsListChangedEventDispatcher },
    { provide: GETS_SELECTED_CLIENT_QUERY, useExisting: ClientsState },
    { provide: SETS_SELECTED_CLIENT_COMMAND, useExisting: ClientsState },
    { provide: GETS_PRODUCT_INFO_QUERY, useExisting: ClientsState },
    { provide: GETS_PRODUCT_LIST_QUERY, useExisting: ClientsState },
    { provide: EDIT_CLIENT_NAME_COMMAND, useExisting: ClientsState },
  ],
})
export class ClientsStateModule {}
