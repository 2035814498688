import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { ClientQuery } from './client.query';

export const EDIT_CLIENT_NAME_COMMAND = new InjectionToken<EditClientNameCommandPort>(
  'EDIT_CLIENT_COMMAND',
);

export interface EditClientNameCommandPort {
  editClientName(client: ClientQuery): Observable<void>;
}
