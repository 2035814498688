import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { WorkspaceDto } from '../secondary/workspace.dto';

export const GETS_ALL_WORKSPACES_QUERY = new InjectionToken<GetsAllWorkspacesQueryPort>(
  'GETS_ALL_WORKSPACES_QUERY',
);

export interface GetsAllWorkspacesQueryPort {
  getAllWorkspacesQuery(): Observable<WorkspaceDto[]>;
}
