import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClientOverviewComponent } from './client-overview.component';
import { RouterModule } from '@angular/router';
import { LanguageModule } from '@app.cobiro.com/common/language';
import { ActivationWidgetComponent } from './activation-widget/activation-widget.component';
import { MatIconModule } from '@angular/material/icon';
import { SubscriptionWidgetComponent } from './subscription-widget/subscription-widget.component';

@NgModule({
  declarations: [ClientOverviewComponent, ActivationWidgetComponent, SubscriptionWidgetComponent],
  imports: [
    CommonModule,
    LanguageModule,
    MatIconModule,
    RouterModule.forChild([{ path: '', component: ClientOverviewComponent }]),
  ],
  exports: [ClientOverviewComponent, ActivationWidgetComponent, SubscriptionWidgetComponent],
})
export class ClientOverviewComponentModule {}
