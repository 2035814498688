import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClientDashboardHeaderComponent } from './client-dashboard-header.component';
import { LanguageModule } from '@app.cobiro.com/common/language';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [ClientDashboardHeaderComponent],
  imports: [CommonModule, LanguageModule, MatIconModule, RouterModule, ReactiveFormsModule],
  exports: [ClientDashboardHeaderComponent],
})
export class ClientDashboardHeaderComponentModule {}
