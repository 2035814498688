/* eslint-disable max-lines-per-function */
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { ChartComponent } from 'ng-apexcharts';
import { ChartOptions } from '../../../../application/ports/primary/chart-option.query';
import { PIE_CHART_OPTION } from '../../../../application/ports/primary/lm-pie-chart-option.query.stub';
import { GETS_LANGUAGE, GetsLanguage } from '@app.cobiro.com/common/language';
import {
  GETS_LABEL_MANAGER_SEGMENTS_QUERY_PORT,
  GetsLabelManagerSegmentsQueryPort,
} from '../../../../application/ports/primary/gets-label-manager-segments.query-port';
import { map, Subject, takeUntil, tap } from 'rxjs';

@Component({
  selector: 'lib-cobiro-pro-label-manager-revenue-and-costs',
  templateUrl: './label-manager-revenue-and-costs.component.html',
  styleUrls: ['./label-manager-revenue-and-costs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LabelManagerRevenueAndCostsComponent implements AfterViewInit, OnDestroy {
  @ViewChild('chartRevenue') chartRevenue: ChartComponent;
  @ViewChild('chartCosts') chartCosts: ChartComponent;
  public chartOptionsRevenue: Partial<ChartOptions>;
  public chartOptionsCosts: Partial<ChartOptions>;

  private readonly _ngDestroy$ = new Subject<void>();

  constructor(
    @Inject(GETS_LABEL_MANAGER_SEGMENTS_QUERY_PORT)
    private readonly _getsLabelManagerSegmentsQueryPort: GetsLabelManagerSegmentsQueryPort,
    @Inject(GETS_LANGUAGE)
    private readonly _languageService: GetsLanguage,
  ) {
    this.chartOptionsRevenue = {
      ...PIE_CHART_OPTION,
      chart: {
        ...PIE_CHART_OPTION.chart,
        events: {
          dataPointSelection: (event, chartContext, opts) =>
            this.selectDataPointCosts(event, opts.dataPointIndex),
          legendClick: (chartContext, seriesIndex, opts) => {
            this.selectDataPointCosts(chartContext, seriesIndex);
            this.selectDataPointRevenue(chartContext, seriesIndex);
          },
        },
      },
      series: [],
      legend: {
        ...PIE_CHART_OPTION.legend,
        formatter: value => {
          return this._languageService.get(`cobiro_pro_label_manager_${value}`);
        },
      },
      title: {
        ...PIE_CHART_OPTION.title,
        text: this._languageService.get('cobiro_pro_label_manager_revenue_title'),
      },
      tooltip: {
        y: {
          formatter: (value, opt) => {
            return `${value.toLocaleString('de-DE')} (${(
              (value * 100) /
              opt.globals.series.reduce((a: number, b: number) => a + b, 0)
            ).toFixed(1)}%)`;
          },
          title: {
            formatter: seriesName =>
              this._languageService.get(`cobiro_pro_label_manager_${seriesName}`) + ': ',
          },
        },
      },
    };
    this.chartOptionsCosts = {
      ...PIE_CHART_OPTION,
      series: [],
      chart: {
        ...PIE_CHART_OPTION.chart,
        events: {
          dataPointSelection: (event, chartContext, opts) =>
            this.selectDataPointRevenue(event, opts.dataPointIndex),
          legendClick: (chartContext, seriesIndex, opts) => {
            this.selectDataPointCosts(chartContext, seriesIndex);
            this.selectDataPointRevenue(chartContext, seriesIndex);
          },
        },
      },
      legend: {
        ...PIE_CHART_OPTION.legend,
        formatter: value => {
          return this._languageService.get(`cobiro_pro_label_manager_${value}`);
        },
      },
      title: {
        ...PIE_CHART_OPTION.title,
        text: this._languageService.get('cobiro_pro_label_manager_costs_title'),
      },
      tooltip: {
        y: {
          formatter: (value, opt) => {
            return `${value.toLocaleString('de-DE')} (${(
              (value * 100) /
              opt.globals.series.reduce((a: number, b: number) => a + b, 0)
            ).toFixed(1)}%)`;
          },
          title: {
            formatter: seriesName =>
              this._languageService.get(`cobiro_pro_label_manager_${seriesName}`) + ': ',
          },
        },
      },
    };
  }
  ngOnDestroy(): void {
    this._ngDestroy$.next();
    this._ngDestroy$.complete();
  }
  ngAfterViewInit(): void {
    this._getsLabelManagerSegmentsQueryPort
      .getSegments()
      .pipe(
        map(res => ({
          labels: PIE_CHART_OPTION.labels,
          costs: PIE_CHART_OPTION.labels.map((key: string) => res.cost[key]),
          conversionsValue: PIE_CHART_OPTION.labels.map((key: string) => res.conversionsValue[key]),
        })),
        tap(res => {
          this.chartCosts.updateOptions({
            ...this.chartOptionsCosts,
            xaxis: {
              categories: res.labels,
            },
          });
          this.chartRevenue.updateOptions({
            ...this.chartOptionsRevenue,
            xaxis: {
              categories: res.labels,
            },
          });
          this.chartRevenue.updateSeries(res.conversionsValue);

          this.chartCosts.updateSeries(res.costs);
        }),
        takeUntil(this._ngDestroy$),
      )
      .subscribe();
  }
  selectDataPointRevenue(event: any, dataPointIndex: number): void {
    if (event) {
      this.chartRevenue.toggleDataPointSelection(dataPointIndex);
    }
  }
  selectDataPointCosts(event: any, dataPointIndex: number): void {
    if (event) {
      this.chartCosts.toggleDataPointSelection(dataPointIndex);
    }
  }
}
