import { ChangeDetectionStrategy, Component, Inject, Input, OnInit } from '@angular/core';
import { APPLICATION_BUS, ApplicationEvent, Dispatcher } from '@cobiro/eda';
import { ManageClientClickedEvent } from '../../../../../application/events/manage-client-clicked.event';
import { ProductQuery } from '../../../../../application/ports/primary/clients/product.query';
import { ActivatedRoute } from '@angular/router';
import { ClientSubscriptionQuery } from '../../../../../application/ports/primary/clients/subscription.query';
import { PRODUCT_LIST } from 'libs/cobiro-pro-rewrite/clients/src/lib/application/ports/primary/clients/product-list.query';
import { firstValueFrom, map } from 'rxjs';
import {
  CANCEL_SUBSCRIPTION_CSS_COMMAND,
  CancelSubscriptionCSSCommandPort,
} from '../../../../../application/ports/primary/clients/cancel-subscription-css-client.command-port';
import {
  CANCEL_SUBSCRIPTION_LM_COMMAND,
  CancelSubscriptionLMCommandPort,
} from '../../../../../application/ports/primary/clients/cancel-subscription-lm-client.command-port';
import {
  GETS_SELECTED_CLIENT_QUERY,
  GetsSelectedClientQueryPort,
} from '../../../../../application/ports/primary/clients/gets-selected-client.query-port';

const STATUS_STYLE = {
  none: 'cs-badge-flat',
  active: 'cs-badge-success',
  pending: 'cs-badge-warning',
  paused: 'cs-badge-error',
};

@Component({
  selector: 'lib-cobiro-pro-subscription-widget',
  templateUrl: './subscription-widget.component.html',
  styleUrls: ['./subscription-widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SubscriptionWidgetComponent {
  statusIcon: string;
  statusTooltip: string;
  statusStyle: string;

  @Input() productInfo: ProductQuery;
  @Input() subscription: ClientSubscriptionQuery;
  @Input() set status(value: string) {
    this.statusIcon = `product-status-${value}`;
    this.statusTooltip = `cobiro_pro_css_integration_status_${value}`;
    this.statusStyle = STATUS_STYLE[value];
  }

  constructor(
    @Inject(CANCEL_SUBSCRIPTION_CSS_COMMAND)
    private readonly _cancelSubscriptionCSSCommand: CancelSubscriptionCSSCommandPort,
    @Inject(CANCEL_SUBSCRIPTION_LM_COMMAND)
    private readonly _cancelSubscriptionLMCommand: CancelSubscriptionLMCommandPort,
    @Inject(GETS_SELECTED_CLIENT_QUERY)
    private readonly _getSelectedClientQuery: GetsSelectedClientQueryPort,
    @Inject(APPLICATION_BUS)
    private readonly _applicationBus: Dispatcher<ApplicationEvent>,
    private readonly _route: ActivatedRoute,
  ) {}

  async onManageClicked(): Promise<void> {
    const workspaces = this._route.snapshot.data['workspaces'];
    const client = await firstValueFrom(this._getSelectedClientQuery.getSelectedClient());
    this._applicationBus.dispatch(
      new ManageClientClickedEvent(
        client.id,
        this.productInfo.name,
        client.workspaceId,
        workspaces,
      ),
    );
  }

  async onCancelSubscriptionClicked(): Promise<void> {
    const userEmail = await firstValueFrom(
      this._route.data.pipe(map(({ userDetails }) => userDetails.email)),
    );
    const client = await firstValueFrom(this._getSelectedClientQuery.getSelectedClient());
    await firstValueFrom(
      this.productInfo.name === PRODUCT_LIST.labelManager
        ? this._cancelSubscriptionLMCommand.cancelSubscriptionLM({
            clientId: client.id,
            subscriptionId: this.subscription.subscriptionId,
            userEmail: userEmail,
          })
        : this._cancelSubscriptionCSSCommand.cancelSubscriptionCSS({
            clientId: client.id,
            siteId: client.siteId,
            userEmail: userEmail,
          }),
    );
  }
}
