import { NgModule } from '@angular/core';
import { HuiSimpleModalWithEmojiModule } from '@app.cobiro.com/shared/hui/simple-modal-with-emoji';
import { ConfirmIntegrationCancelDialogComponentModule } from '../../adapters/primary/ui/confirm-integration-cancel-dialog/confirm-integration-cancel-dialog.component-module';
import { IntegrationIdChangedEventDispatcher } from '../../adapters/secondary/dispatchers/integration-id-changed.event-dispatcher';
import { CANCELS_INTEGRATION_COMMAND } from '../ports/primary/cancels-integration.command-port';
import { CREATES_INTEGRATION_COMMAND } from '../ports/primary/creates-integration.command-port';
import { EDITS_INTEGRATION_COMMAND } from '../ports/primary/edits-integration.command-port';
import { ENTERS_INTEGRATION_DETAILS_EDIT_MODE_COMMAND } from '../ports/primary/enters-integration-details-edit-mode.command-port';
import { GETS_COBIRO_CSS_META_QUERY_PORT } from '../ports/primary/gets-cobiro-css-meta.query-port';
import { GETS_CSS_MERCHANT_IDS_QUERY_PORT } from '../ports/primary/gets-css-merchant-ids.query-port';
import { GETS_FORM_ERROR_QUERY_PORT } from '../ports/primary/gets-form-error.query-port';
import { GETS_FORM_STATUS_QUERY } from '../ports/primary/gets-form-status.query-port';
import { GETS_INSTALLED_APPS_QUERY_PORT } from '../ports/primary/gets-installed_apps.query-port';
import { GETS_INTEGRATION_ERROR_QUERY } from '../ports/primary/gets-integration-error.query-port';
import { GETS_INTEGRATION_LINK_STATUS_QUERY } from '../ports/primary/gets-integration-link-status.query-port';
import { GETS_INTEGRATION_PAUSE_STATUS_QUERY } from '../ports/primary/gets-integration-pause-status.query-port';
import { GETS_INTEGRATION_STATUS_QUERY } from '../ports/primary/gets-integration-status.query-port';
import { GETS_INTEGRATION_QUERY } from '../ports/primary/gets-integration.query-port';
import { GETS_OWNED_INTEGRATION_PANEL_QUERY } from '../ports/primary/gets-owned-integration-panel.query-port';
import { GETS_SWITCHED_INTEGRATION_PANEL_QUERY } from '../ports/primary/gets-switched-integration-panel.query-port';
import { INITS_STEPPER_COMMAND } from '../ports/primary/inits-stepper.command-port';
import { IS_CREATING_INTEGRATION_QUERY } from '../ports/primary/is-integration-creating.query-port';
import { IS_EDITING_INTEGRATION_QUERY } from '../ports/primary/is-integration-editing.query-port';
import { IS_START_PAGE_SHOWN_QUERY } from '../ports/primary/is-start-page-shown.query-port';
import { LINKS_INTEGRATION_COMMAND } from '../ports/primary/links-integration.command-port';
import { LOADS_INTEGRATION_COMMAND } from '../ports/primary/loads-integration.command-port';
import { STARTS_INTEGRATION_PROCESS_COMMAND } from '../ports/primary/starts-integration-process.command-port';
import { SWITCHES_CSS_DOMAIN_COMMAND } from '../ports/primary/switches-css-domain.command-port';
import { SWITCHES_INTEGRATION_COMMAND } from '../ports/primary/switches-integration.command-port';
import { UPGRADES_INTEGRATION_QUERY } from '../ports/primary/upgrades-integration.query-port';
import { INTEGRATION_ID_CHANGED_DISPATCHER } from '../ports/secondary/dispatchers/integration-id-changed.dispatcher-port';
import { GoogleCssIntegrationState } from './google-css-integration.state';
import { IS_TRIAL_PERIOD_QUERY } from '../ports/primary/is-trial-period.query-port';
import { GETS_TRIAL_PERIOD_QUERY } from '../ports/primary/gets-trial-period.query-port';

@NgModule({
  imports: [ConfirmIntegrationCancelDialogComponentModule, HuiSimpleModalWithEmojiModule],
  providers: [
    GoogleCssIntegrationState,
    {
      provide: CANCELS_INTEGRATION_COMMAND,
      useExisting: GoogleCssIntegrationState,
    },
    {
      provide: CREATES_INTEGRATION_COMMAND,
      useExisting: GoogleCssIntegrationState,
    },
    {
      provide: EDITS_INTEGRATION_COMMAND,
      useExisting: GoogleCssIntegrationState,
    },
    {
      provide: ENTERS_INTEGRATION_DETAILS_EDIT_MODE_COMMAND,
      useExisting: GoogleCssIntegrationState,
    },
    {
      provide: GETS_FORM_STATUS_QUERY,
      useExisting: GoogleCssIntegrationState,
    },
    {
      provide: GETS_INTEGRATION_QUERY,
      useExisting: GoogleCssIntegrationState,
    },
    {
      provide: GETS_INTEGRATION_STATUS_QUERY,
      useExisting: GoogleCssIntegrationState,
    },
    { provide: GETS_INTEGRATION_ERROR_QUERY, useExisting: GoogleCssIntegrationState },
    { provide: GETS_INTEGRATION_LINK_STATUS_QUERY, useExisting: GoogleCssIntegrationState },
    {
      provide: GETS_INTEGRATION_PAUSE_STATUS_QUERY,
      useExisting: GoogleCssIntegrationState,
    },
    {
      provide: GETS_OWNED_INTEGRATION_PANEL_QUERY,
      useExisting: GoogleCssIntegrationState,
    },
    {
      provide: GETS_SWITCHED_INTEGRATION_PANEL_QUERY,
      useExisting: GoogleCssIntegrationState,
    },
    {
      provide: LINKS_INTEGRATION_COMMAND,
      useExisting: GoogleCssIntegrationState,
    },
    {
      provide: LOADS_INTEGRATION_COMMAND,
      useExisting: GoogleCssIntegrationState,
    },
    {
      provide: SWITCHES_INTEGRATION_COMMAND,
      useExisting: GoogleCssIntegrationState,
    },
    {
      provide: GETS_COBIRO_CSS_META_QUERY_PORT,
      useExisting: GoogleCssIntegrationState,
    },
    {
      provide: IS_CREATING_INTEGRATION_QUERY,
      useExisting: GoogleCssIntegrationState,
    },
    {
      provide: IS_EDITING_INTEGRATION_QUERY,
      useExisting: GoogleCssIntegrationState,
    },
    {
      provide: INITS_STEPPER_COMMAND,
      useExisting: GoogleCssIntegrationState,
    },
    {
      provide: GETS_INSTALLED_APPS_QUERY_PORT,
      useExisting: GoogleCssIntegrationState,
    },
    {
      provide: GETS_FORM_ERROR_QUERY_PORT,
      useExisting: GoogleCssIntegrationState,
    },
    {
      provide: STARTS_INTEGRATION_PROCESS_COMMAND,
      useExisting: GoogleCssIntegrationState,
    },
    {
      provide: UPGRADES_INTEGRATION_QUERY,
      useExisting: GoogleCssIntegrationState,
    },
    { provide: INTEGRATION_ID_CHANGED_DISPATCHER, useClass: IntegrationIdChangedEventDispatcher },
    {
      provide: GETS_CSS_MERCHANT_IDS_QUERY_PORT,
      useExisting: GoogleCssIntegrationState,
    },
    {
      provide: SWITCHES_CSS_DOMAIN_COMMAND,
      useExisting: GoogleCssIntegrationState,
    },
    {
      provide: IS_START_PAGE_SHOWN_QUERY,
      useExisting: GoogleCssIntegrationState,
    },
    {
      provide: IS_TRIAL_PERIOD_QUERY,
      useExisting: GoogleCssIntegrationState,
    },
    {
      provide: GETS_TRIAL_PERIOD_QUERY,
      useExisting: GoogleCssIntegrationState,
    },
  ],
})
export class GoogleCssIntegrationStateModule {}
