import { ApplicationEventsHandler, ongoingEventsOrchestrationStrategy } from '@cobiro/eda';
import { Inject, Injectable } from '@angular/core';
import { CobiroProShopCreationEvent } from '@app.cobiro.com/core/events';
import { take, tap } from 'rxjs';
import {
  CREATE_CLIENT_COMMAND,
  CreateClientCommandPort,
} from '../../../application/ports/primary/clients/create-client-command.port';
import { ActivatedRoute, Router } from '@angular/router';
import { TEAM_ID_GETTER, TeamIdGetter } from '@app.cobiro.com/cobiro-pro/context';

@Injectable()
export class CobiroProShopCreationEventHandler implements ApplicationEventsHandler {
  eventsClasses = [CobiroProShopCreationEvent];
  strategy = ongoingEventsOrchestrationStrategy;

  constructor(
    @Inject(CREATE_CLIENT_COMMAND)
    private readonly _createClientCommand: CreateClientCommandPort,
    @Inject(TEAM_ID_GETTER)
    private readonly _teamIdGetter: TeamIdGetter,
    private readonly _router: Router,
  ) {}

  handle([event]: [CobiroProShopCreationEvent]): void {
    this._createClientCommand
      .createClient({ route: event.route, globalCreation: event.globalCreation })
      .pipe(
        tap(({ id }) =>
          this._router.navigate([
            '/',
            'connect',
            this._teamIdGetter.getTeamId(),
            'shops',
            id,
            'overview',
          ]),
        ),
        take(1),
      )
      .subscribe();
  }
}
