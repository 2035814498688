import { NgModule } from '@angular/core';
import { HttpClientsService } from './http-clients.service';
import { GETS_ALL_TEAM_CLIENT_DTO } from '../../../../application/ports/secondary/gets-all-team-client.dto-port';

@NgModule({
  providers: [
    HttpClientsService,
    { provide: GETS_ALL_TEAM_CLIENT_DTO, useExisting: HttpClientsService },
  ],
})
export class HttpClientsServiceModule {}
