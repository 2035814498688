<div
  class="d-flex flex-column flex-md-row flex-wrap justify-content-between flex-1 cs-card cs-p-40 cs-mb-10 cs-mr-10 align-items-md-center align-items-start text-center"
>
  <div
    class="d-flex cs-width-40-pc-lg cs-width-50-pc-md cs-width-100-pc-xs flex-row align-items-center"
  >
    <img class="cs-width-50-xs cs-height-50-xs cs-mr-10" [src]="productInfo.img" />
    <div class="d-flex flex-column">
      <p class="cs-title-5 cs-white-space-nowrap text-left">
        {{ 'cobiro_pro_clients_table_' + productInfo.name | language }}
      </p>
      <p class="cs-text-1 cs-white-space-nowrap text-left">
        {{
          subscription.isTrial
            ? ('_trial_until' | language) + (subscription.trialExpiresAt | date : 'dd/MM/YYYY')
            : ('cobiro_pro_clients_table_plan_' + subscription.planType.label | language)
        }}
      </p>
    </div>
  </div>
  <div class="d-flex cs-width-30-pc-lg cs-width-50-pc-md cs-width-100-pc-xs cs-mt-20 cs-mv-0-md">
    <span
      [class]="statusStyle"
      class="d-flex align-items-center cs-badge br-15 cs-white-space-nowrap"
    >
      <mat-icon class="cs-size-sm cs-mr-5" [svgIcon]="statusIcon"></mat-icon>
      {{ statusTooltip | language }}
    </span>
  </div>
  <div
    class="d-flex flex-column flex-md-row align-items-start align-items-md-center justify-content-start justify-content-lg-end cs-mt-20 cs-mt-0-lg cs-width-30-pc-lg cs-width-100-pc-xs"
  >
    <button
      class="cs-btn cs-btn-sm cs-btn-error cs-mr-10 cs-mb-10 cs-mb-0-md button-width"
      (click)="onCancelSubscriptionClicked()"
    >
      <mat-icon class="cs-mr-10" svgIcon="forbid"></mat-icon>
      {{ 'site_cancel_subscription_popup_submit_button' | language }}
    </button>
    <button class="cs-btn cs-btn-sm cs-btn-primary button-width" (click)="onManageClicked()">
      {{ '_manage' | language }}
      <mat-icon class="cs-ml-10" svgIcon="arrow-right"></mat-icon>
    </button>
  </div>
</div>
