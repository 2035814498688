import { ApplicationEvent } from '@cobiro/eda';

export class ManageClientClickedEvent extends ApplicationEvent {
  constructor(
    public readonly clientId: string,
    public readonly productName: string = 'css',
    public readonly workspaceId: string,
    public readonly workspaceList: any,
  ) {
    super();
  }
}
