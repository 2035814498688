import { NgModule } from '@angular/core';
import { HttpWorkspacesService } from './http-workspaces.service';
import { GETS_MEMBER_WORKSPACES_DTO } from '../../../../application/ports/secondary/dto/workspaces/gets-member-workspaces.dto-port';

@NgModule({
  providers: [
    HttpWorkspacesService,
    { provide: GETS_MEMBER_WORKSPACES_DTO, useExisting: HttpWorkspacesService },
  ],
})
export class HttpWorkspacesServiceModule {}
