/* eslint-disable complexity */
/* eslint-disable max-lines-per-function */
/* eslint-disable max-len */
import { Inject, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FeatureFlagsState } from '@cobiro/ng-feature-flags';
import {
  BehaviorSubject,
  catchError,
  combineLatest,
  concatMap,
  delay,
  distinctUntilChanged,
  map,
  Observable,
  of,
  Subject,
  switchMap,
  take,
  tap,
  throwError,
} from 'rxjs';
import { AppliesDiscountCodeCommand } from '../../ports/primary/payments/applies-discount-code.command';
import { AppliesDiscountCodeCommandPort } from '../../ports/primary/payments/applies-discount-code.command-port';
import { CardQuery } from '../../ports/primary/payments/card.query';
import { GetsCardsListCommandPort } from '../../ports/primary/payments/gets-cards-list.command-port';
import { GetsDiscountCommandPort } from '../../ports/primary/payments/gets-discount.command-port';
import { GetsPaymentPlansQueryPort } from '../../ports/primary/payments/gets-payment-plans.query-port';
import { GetsPaymentSourcesQueryPort } from '../../ports/primary/payments/gets-payment-sources.query-port';
import { GetsPlanEstimateQueryPort } from '../../ports/primary/payments/gets-plan-estimate.query-port';
import { GetsSelectedCardQueryPort } from '../../ports/primary/payments/gets-selected-card.query-port';
import { GetsSelectedPlanQueryPort } from '../../ports/primary/payments/gets-selected-plan.query-port';
import { PAYMENT_PLAN_FEATURE_PLANS_MAP } from '../../ports/primary/payments/payment-plan-feature-flags';
import {
  PaymentPlanOptionQuery,
  PaymentPlanPeriod,
} from '../../ports/primary/payments/payment-plan-options.query';
import { PlanEstimateQuery } from '../../ports/primary/payments/plan-estimate.query';
import { SetsSelectedCardCommand } from '../../ports/primary/payments/sets-selected-card.command';
import { SetsSelectedCardCommandPort } from '../../ports/primary/payments/sets-selected-card.command-port';
import { SetsSelectedPlanCommand } from '../../ports/primary/payments/sets-selected-plan.command';
import { SetsSelectedPlanCommandPort } from '../../ports/primary/payments/sets-selected-plan.command-port';
import { UpgradesPlanCommandPort } from '../../ports/primary/payments/upgrades-plan-client.command-port';
import {
  CHECKS_DISCOUNT_CODE_DTO,
  ChecksDiscountCodeDtoPort,
} from '../../ports/secondary/dto/payments/checks-discount-code.dto-port';
import { BillingDataDto } from '../../ports/secondary/dto/payments/billing-data.dto';
import { CardDto } from '../../ports/secondary/dto/payments/card.dto';
import { DiscountDto } from '../../ports/secondary/dto/payments/discount.dto';
import {
  GETS_BILLING_DATA_DTO_PORT,
  GetsBillingDataDtoPort,
} from '../../ports/secondary/dto/payments/gets-billing-data-dto.port';
import {
  GETS_PAYMENT_PLANS_DTO,
  GetsPaymentPlansDtoPort,
} from '../../ports/secondary/dto/payments/gets-payment-plans.dto-port';
import {
  GETS_PAYMENT_SOURCES_DTO,
  GetsPaymentSourcesDtoPort,
} from '../../ports/secondary/dto/payments/gets-payment-sources.dto-port';
import {
  GETS_PLAN_ESTIMATE_DTO,
  GetsPlanEstimateDtoPort,
} from '../../ports/secondary/dto/payments/gets-plan-estimate.dto-port';
import { PaymentPlanDto } from '../../ports/secondary/dto/payments/payment-plan.dto';
import { PlanEstimateDto } from '../../ports/secondary/dto/payments/plan-estimate.dto';
import {
  CreatesNewSubscriptionDtoPort,
  CREATES_NEW_SUBSCRIPTION_DTO,
} from '../../ports/secondary/dto/payments/creates-new-subscription.dto-port';
import { CreatesNewSubscriptionCommandPort } from '../../ports/primary/payments/creates-new-subscription.command-port';
import { CreatesNewSubscriptionCommand } from '../../ports/primary/payments/creates-new-subscription.command';
import { HuiAlert } from '@app.cobiro.com/shared/hui/alert';
import { UpgradesPlanCommand } from '../../ports/primary/payments/upgrades-plan-client.command';
import {
  SELECTS_CLIENTS_CONTEXT_STORAGE,
  SelectsClientsContextStoragePort,
} from '../../ports/secondary/context/selects-clients-context.storage-port';
import {
  CLIENTS_LIST_CHANGED_DISPATCHER,
  ClientsListChangedDispatcherPort,
} from '../../ports/secondary/dispatchers/clients-list-changed.dispatcher-port';
import { ClientDTO } from '../../ports/secondary/dto/clients/client.dto';
import { PAYMENT_PLAN_ERROR_MAP } from '../../ports/primary/payments/payment-plan-error.query';
import {
  TeamIdGetter,
  TEAM_ID_GETTER,
  WorkspaceIdGetter,
  WORKSPACE_ID_GETTER,
} from '@app.cobiro.com/cobiro-pro/context';
import { ResetsDiscountCodeCommandPort } from '../../ports/primary/payments/resets-discount-code.command-port';
import { AddPaymentMethodCommand } from '../../ports/primary/payments/adds-payment-method.command';
import { AddsPaymentMethodCommandPort } from '../../ports/primary/payments/adds-payment-method.command-port';
import { ConfirmPaymentMethodDto } from '../../ports/secondary/dto/payments/confirm-payment-method.dto';
import {
  CreatesPaymentIntentDtoPort,
  CREATES_PAYMENT_INTENT_DTO_PORT,
} from '../../ports/secondary/dto/payments/creates-payment-intent.dto-port';
import {
  ConfirmsPaymentMethodDtoPort,
  CONFIRMS_PAYMENT_METHOD_DTO_PORT,
} from '../../ports/secondary/dto/payments/confirms-payment-method.dto-port';
import { PaymentIntentDto } from '../../ports/secondary/dto/payments/payment-intent.dto';
import { PaymentIntent } from '../../ports/primary/payments/payment-intent.query';
import {
  CreatesPaymentSourceDtoPort,
  CREATES_PAYMENT_SOURCE_DTO_PORT,
} from '../../ports/secondary/dto/payments/creates-payment-source.dto-port';
import { GetsCardValidationQueryPort } from '../../ports/primary/payments/gets-card-validation-error.query-port';
import { ApplicationBus, APPLICATION_BUS } from '@cobiro/eda';
import {
  PaymentConfirmEvent,
  PaymentFlowStartedEvent,
  PaymentMethodEvent,
  PaymentPlanSelectedEvent,
  PlanPaymentCompletedEvent,
  PlanPaymentStartedEvent,
  PlanSelectedEvent,
} from '@app.cobiro.com/core/events';
import {
  SetsSelectedClientCommandPort,
  SETS_SELECTED_CLIENT_COMMAND,
} from '../../ports/primary/clients/sets-selected-client-command.port';
import { STORAGE } from '@app.cobiro.com/core/storage';
import { CallPaymentMethodCommandPort } from '../../ports/primary/payments/calls-payment-methods-event.command-port';
import {
  GetsClientSubsciptionsDtoPort,
  GETS_CLIENT_SUBSCRIPTIONS_DTO_PORT,
} from '../../ports/secondary/dto/clients/gets-client-subscriptions.dto-port';
import { SubscriptionDto } from '../../ports/secondary/dto/clients/subscription.dto';
import { GetsLanguage, GETS_LANGUAGE } from '@app.cobiro.com/common/language';
import { ClientsContext } from '../../ports/secondary/context/clientsContext';
import { PRODUCT_LIST } from '../../ports/primary/clients/product-list.query';
import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { ProductInfoComponent } from '../../../adapters/primary/ui/modals/product-info/product-info.component';
import {
  PaymentFlowStartedDispatcherPort,
  PAYMENT_FLOW_STARTED_DISPATCHER,
} from '../../ports/secondary/dispatchers/payment-flow-started.dispatcher-port ';
import { Router } from '@angular/router';
import { GetsPaymentPlansQuery } from '../../ports/primary/payments/gets-payment-plans.query';

@Injectable()
export class PaymentsState
  implements
    GetsPaymentPlansQueryPort,
    UpgradesPlanCommandPort,
    GetsCardsListCommandPort,
    GetsSelectedCardQueryPort,
    SetsSelectedCardCommandPort,
    AppliesDiscountCodeCommandPort,
    GetsDiscountCommandPort,
    GetsPlanEstimateQueryPort,
    GetsPaymentSourcesQueryPort,
    GetsSelectedPlanQueryPort,
    SetsSelectedPlanCommandPort,
    CreatesNewSubscriptionCommandPort,
    ResetsDiscountCodeCommandPort,
    AddsPaymentMethodCommandPort,
    GetsCardValidationQueryPort,
    CallPaymentMethodCommandPort
{
  private readonly _errorFields$ = new Subject<string>();
  private _cardList$ = new BehaviorSubject<CardQuery[] | null>(null);
  private _selectedCardId$ = new BehaviorSubject<string | null>(null);
  private _selectedPaymentPlan$ = new BehaviorSubject<string | null>('business-monthly');
  private _appliedDiscountCode$ = new BehaviorSubject<DiscountDto | null>(null);
  _planEstimation$ = new BehaviorSubject<PlanEstimateDto | null>(null);
  private _shopSubscriptions$ = new BehaviorSubject<SubscriptionDto[] | null>(null);

  constructor(
    @Inject(GETS_PAYMENT_PLANS_DTO)
    private readonly _getsPaymentPlans: GetsPaymentPlansDtoPort,
    @Inject(GETS_PAYMENT_SOURCES_DTO)
    private readonly _getsPaymentSources: GetsPaymentSourcesDtoPort,
    @Inject(CHECKS_DISCOUNT_CODE_DTO)
    private readonly _checksDiscountCode: ChecksDiscountCodeDtoPort,
    @Inject(GETS_PLAN_ESTIMATE_DTO)
    private readonly _getsPlanEstimate: GetsPlanEstimateDtoPort,
    @Inject(GETS_BILLING_DATA_DTO_PORT)
    private readonly _getBillingData: GetsBillingDataDtoPort,
    @Inject(CREATES_NEW_SUBSCRIPTION_DTO)
    private readonly _createNewSubscription: CreatesNewSubscriptionDtoPort,
    @Inject(CLIENTS_LIST_CHANGED_DISPATCHER)
    private readonly _clientsListChangedDispatcher: ClientsListChangedDispatcherPort,
    @Inject(SELECTS_CLIENTS_CONTEXT_STORAGE)
    private readonly _selectsClientsContextStorage: SelectsClientsContextStoragePort,
    private readonly _matDialog: MatDialog,
    private readonly _featureFlagsState: FeatureFlagsState,
    private readonly _alert: HuiAlert,
    @Inject(WORKSPACE_ID_GETTER)
    private readonly _workspaceIdGetter: WorkspaceIdGetter,
    @Inject(CREATES_PAYMENT_INTENT_DTO_PORT)
    private readonly _createPaymentIntentDto: CreatesPaymentIntentDtoPort,
    @Inject(CONFIRMS_PAYMENT_METHOD_DTO_PORT)
    private readonly _confirmsPaymentMethod: ConfirmsPaymentMethodDtoPort,
    @Inject(CREATES_PAYMENT_SOURCE_DTO_PORT)
    private readonly _createsPaymentSourceDtoPort: CreatesPaymentSourceDtoPort,
    @Inject(APPLICATION_BUS) private readonly _applicationBus: ApplicationBus,
    @Inject(SETS_SELECTED_CLIENT_COMMAND)
    private readonly _setSelectedClientCommand: SetsSelectedClientCommandPort,
    @Inject(GETS_CLIENT_SUBSCRIPTIONS_DTO_PORT)
    private readonly _getsClientSubsciptionsDtoPort: GetsClientSubsciptionsDtoPort,
    @Inject(STORAGE)
    private readonly _storage: Storage,
    @Inject(GETS_LANGUAGE) private readonly _languageService: GetsLanguage,
    @Inject(PAYMENT_FLOW_STARTED_DISPATCHER)
    private readonly _paymentFlowStartedDispatcher: PaymentFlowStartedDispatcherPort,
    @Inject(TEAM_ID_GETTER)
    private readonly _teamIdGetter: TeamIdGetter,
    private readonly _router: Router,
  ) {}

  dispatchEvent(): Observable<void> {
    const workspaceId = this._workspaceIdGetter.getWorkspaceId();
    return of(
      this._applicationBus.dispatch(new PaymentMethodEvent(workspaceId, this._getSiteId())),
    );
  }

  getErrors(): Observable<string> {
    return this._errorFields$.asObservable();
  }

  createNewSubscription(command: CreatesNewSubscriptionCommand): Observable<void> {
    const workspaceId = this._workspaceIdGetter.getWorkspaceId();
    return combineLatest([
      this._selectedPaymentPlan$.asObservable(),
      this._appliedDiscountCode$.asObservable(),
      this._selectedCardId$.asObservable(),
    ]).pipe(
      switchMap(([planId, discountCode, cardId]: [string, DiscountDto, string]) => {
        this._applicationBus.dispatch(
          new PlanPaymentStartedEvent(
            `Subscription Plan - ${planId} ${command.isTrial ? '- trial' : ''}`,
          ),
        );
        return this._createNewSubscription
          .createNewSubscription({
            clientId: command.siteId,
            companyName: command.companyName,
            planId: planId,
            cardId: cardId,
            discountCode: discountCode ? discountCode.code : null,
            workspaceId: workspaceId,
            isTrial: command.isTrial,
          })
          .pipe(
            switchMap(() => {
              return this._selectsClientsContextStorage.select().pipe(
                take(1),
                map(clientsContext => clientsContext.list),
                delay(3000),
                tap({
                  next: (clients: ClientDTO[]) => {
                    const updatedClients: ClientDTO[] = clients.map(client =>
                      client.siteId === command.siteId && client.companyName === command.companyName
                        ? {
                            ...client,
                            subscriptions: [
                              ...client.subscriptions,
                              new SubscriptionDto(
                                false,
                                null,
                                SubscriptionDto.extractProductName(
                                  this._selectedPaymentPlan$.getValue(),
                                ),
                                SubscriptionDto.extractPlanType(
                                  this._selectedPaymentPlan$.getValue(),
                                ),
                                null,
                              ),
                            ],
                            productStatuses: {
                              css: client.cssIntegrationStatus,
                              'label-manager':
                                SubscriptionDto.extractProductName(
                                  this._selectedPaymentPlan$.getValue(),
                                ) === 'label-manager'
                                  ? ('active' as const)
                                  : null,
                            },
                          }
                        : client,
                    );
                    this._clientsListChangedDispatcher.dispatch({
                      updatedClients: [...updatedClients],
                    });

                    const estimate = this._planEstimation$.getValue();
                    const planId = this._selectedPaymentPlan$.getValue();
                    this._applicationBus.dispatch(
                      new PlanPaymentCompletedEvent(
                        true,
                        planId,
                        null,
                        null,
                        null,
                        estimate.gross,
                        estimate.vatValue,
                        null,
                        command.isTrial,
                      ),
                    );
                    this._alert.open('success', 'payment_plans_upgrade_success_description');
                  },
                  error: () => {
                    const estimate = this._planEstimation$.getValue();
                    const planId = this._selectedPaymentPlan$.getValue();
                    this._applicationBus.dispatch(
                      new PlanPaymentCompletedEvent(
                        false,
                        planId,
                        null,
                        null,
                        null,
                        estimate.gross,
                        estimate.vatValue,
                        null,
                        command.isTrial,
                      ),
                    );
                    this._alert.open('error', 'payment_failed_title');
                  },
                }),
              );
            }),
            catchError(error => {
              const errorDetails = error.error.errors[0].detail;
              const estimate = this._planEstimation$.getValue();
              const planId = this._selectedPaymentPlan$.getValue();
              this._applicationBus.dispatch(
                new PlanPaymentCompletedEvent(
                  false,
                  planId,
                  null,
                  null,
                  null,
                  estimate.gross,
                  estimate.vatValue,
                  null,
                  command.isTrial,
                ),
              );
              this._alert.open(
                'error',
                `${this._languageService.get('payment_failed_title_with_reason')} ${errorDetails}`,
              );
              return of(void 0);
            }),
          );
      }),
      map(() => void 0),
    );
  }

  getPaymentSourcesQuery(): Observable<CardQuery[]> {
    const workspaceId = this._workspaceIdGetter.getWorkspaceId();
    return this._getsPaymentSources.get(workspaceId).pipe(
      map((cards: CardDto[]) => cards.map((card: CardDto) => CardQuery.fromDto(card))),
      tap((cardsQuery: CardQuery[]) => {
        this._cardList$.next(cardsQuery);
        if (cardsQuery.length > 0) {
          this._selectedCardId$.next(cardsQuery.at(0).id);
        }
      }),
    );
  }

  getPlan(): Observable<string> {
    return this._selectedPaymentPlan$.asObservable();
  }

  setPlan(command: SetsSelectedPlanCommand): Observable<void> {
    this._applicationBus.dispatch(new PlanSelectedEvent(`Selected subscription plan`, command.id));
    return of(this._selectedPaymentPlan$.next(command.id));
  }

  getCard(): Observable<string> {
    return this._selectedCardId$.asObservable();
  }

  setCard(command: SetsSelectedCardCommand): Observable<void> {
    return of(this._selectedCardId$.next(command.id));
  }

  getCards(): Observable<CardQuery[]> {
    return this._cardList$.asObservable();
  }

  getDiscount(): Observable<DiscountDto> {
    return this._appliedDiscountCode$.asObservable();
  }

  getPlanEstimate(): Observable<PlanEstimateQuery> {
    const workspaceId = this._workspaceIdGetter.getWorkspaceId();
    return combineLatest([
      this._selectedPaymentPlan$.asObservable(),
      this._appliedDiscountCode$.asObservable(),
      this._getBillingData.getBillingData(workspaceId),
    ]).pipe(
      switchMap(([planId, discountCode, billingData]: [string, DiscountDto, BillingDataDto]) =>
        this._getsPlanEstimate
          .getPlanEstimate({
            planId: planId,
            countryCode: billingData.countryCode,
            vatNumber: billingData.vatNumber,
            discountCode: discountCode ? discountCode.code : null,
          })
          .pipe(
            tap((dto: PlanEstimateDto) =>
              this._applicationBus.dispatch(
                new PaymentConfirmEvent(workspaceId, planId, billingData, discountCode, dto),
              ),
            ),
            map((dto: PlanEstimateDto) => {
              this._planEstimation$.next(dto);
              return PlanEstimateQuery.fromDto(dto);
            }),
          ),
      ),
    );
  }

  applyDiscountCode(command: AppliesDiscountCodeCommand): Observable<string> {
    return this._selectedPaymentPlan$.asObservable().pipe(
      switchMap(selectedPaymentPlan => {
        return this._checksDiscountCode.checkDiscountCode({
          code: command.code,
          planId: selectedPaymentPlan,
        });
      }),
      map(discountCodeCheckResult => {
        if (discountCodeCheckResult.discount) {
          this._appliedDiscountCode$.next(discountCodeCheckResult.discount);
        }
        return null;
      }),
      catchError(err => {
        const errorCode = err.error.errors[0].code;
        return of(PAYMENT_PLAN_ERROR_MAP.get(errorCode));
      }),
    );
  }

  resetDiscountCode(): Observable<void> {
    return of(this._appliedDiscountCode$.next(null));
  }

  getPaymentPlans(command: GetsPaymentPlansQuery): Observable<PaymentPlanOptionQuery[]> {
    if (command.productName === 'css') {
      command.productName = 'business';
    }
    return combineLatest([
      this._getsPaymentPlans.getPaymentPlans(),
      this._getsClientSubsciptionsDtoPort.getSubscriptions({
        workspaceId: this._workspaceIdGetter.getWorkspaceId(),
        clientId: command.clientId,
      }),
    ]).pipe(
      switchMap(([plans, subscriptions]: [PaymentPlanDto[], SubscriptionDto[]]) => {
        this._shopSubscriptions$.next(subscriptions);
        const enabledPlans = plans.map(plan => {
          const planFeature = PAYMENT_PLAN_FEATURE_PLANS_MAP.get(plan.name.toLocaleLowerCase());
          return this._featureFlagsState
            .hasFlags([planFeature])
            .pipe(map(isEnabled => (isEnabled ? plan : undefined)));
        });
        return combineLatest(enabledPlans).pipe(
          map((enabledPlans: (PaymentPlanDto | undefined)[]) =>
            enabledPlans.filter(x => x !== undefined),
          ),
        );
      }),
      map(plans => {
        const isBundlePriceUsed =
          command.productName === 'label-manager' &&
          this._shopSubscriptions$.getValue() &&
          this._shopSubscriptions$
            .getValue()
            .some(subscription => subscription.productName === 'css');
        return this._mapPlanDtoToPlanOptionQuery(
          plans.filter((enabledPlan: PaymentPlanDto) => {
            return enabledPlan.name.toLocaleLowerCase() === command.productName;
          }),
          isBundlePriceUsed,
        );
      }),
      tap(res => {
        this._applicationBus.dispatch(
          new PaymentPlanSelectedEvent(this._workspaceIdGetter.getWorkspaceId()),
        );
        this.setPlan({ id: res.find(plan => plan.period === PaymentPlanPeriod.YEARLY).id });
      }),
    );
  }

  upgradePlan(command: UpgradesPlanCommand): Observable<void> {
    return this._getsClientSubsciptionsDtoPort
      .getSubscriptions({
        workspaceId: this._workspaceIdGetter.getWorkspaceId(),
        clientId: command.clientId,
      })
      .pipe(
        switchMap((subscriptions: SubscriptionDto[]) => {
          this._shopSubscriptions$.next(subscriptions);
          return !subscriptions.some(
            subscription => subscription.productName === command.productName,
          )
            ? this.showPaymentFlow(command)
            : this._selectsClientsContextStorage.select().pipe(
                take(1),
                map(clientsContext => clientsContext.list),
                tap((clients: ClientDTO[]) => {
                  const updatedClients: ClientDTO[] = clients.map((client: ClientDTO) =>
                    client.id === command.clientId
                      ? {
                          ...client,
                          ...{
                            subscriptions: subscriptions,
                            productStatuses: {
                              css: client.cssIntegrationStatus,
                              'label-manager': subscriptions.find(
                                subs => subs.productName === 'label-manager',
                              )
                                ? ('active' as const)
                                : null,
                            },
                          },
                        }
                      : client,
                  );
                  this._clientsListChangedDispatcher.dispatch({
                    updatedClients: [...updatedClients],
                  });
                  return void 0;
                }),
              );
        }),
        map(() => void 0),
      );
  }
  private showPaymentFlow(command: UpgradesPlanCommand): Observable<void> {
    return combineLatest([
      this._setSelectedClientCommand.setSelectedClient(command.clientId),
      this._selectsClientsContextStorage
        .select()
        .pipe(
          distinctUntilChanged(
            (previous, current) => JSON.stringify(previous) === JSON.stringify(current),
          ),
        ),
    ]).pipe(
      switchMap(([client, context]: [ClientDTO, ClientsContext]) => {
        const showMarketingPopup =
          command.productName === PRODUCT_LIST.css
            ? !context.isCssSubscriptions
            : !context.isLMSubscriptions;
        this._paymentFlowStartedDispatcher.dispatch(
          new PaymentFlowStartedEvent(command.clientId, command.productName),
        );
        return showMarketingPopup
          ? this._matDialog
              .open(ProductInfoComponent, {
                panelClass: 'cs-mat-dialog',
                scrollStrategy: new NoopScrollStrategy(),
                autoFocus: false,
                disableClose: false,
                data: {
                  product: command.productName,
                },
              })
              .afterClosed()
          : this._router.navigate([
              '/',
              'connect',
              this._teamIdGetter.getTeamId(),
              'shops',
              'setup',
            ]);
      }),
      map(() => void 0),
    );
  }
  addPaymentMethod(command: AddPaymentMethodCommand): Observable<boolean> {
    const workspaceId = this._workspaceIdGetter.getWorkspaceId();
    return this._createPaymentIntent(workspaceId).pipe(
      map(paymentIntent => ({ paymentIntent, workspaceId })),
      concatMap(({ paymentIntent, workspaceId }) => {
        const dto: ConfirmPaymentMethodDto = {
          card: {
            number: command.number,
            cvv: command.cvv,
            expiryMonth: command.expiryMonth,
            expiryYear: command.expiryYear,
            owner: command.cardOwner,
          },
          paymentIntent: {
            id: paymentIntent.id,
            status: paymentIntent.status,
            amount: paymentIntent.amount,
            currencyCode: paymentIntent.currencyCode,
            gatewayAccountId: paymentIntent.gatewayAccountId,
            gateway: paymentIntent.gateway,
          },
        };
        return this._confirmsPaymentMethod.confirm(dto).pipe(
          switchMap(success =>
            typeof success === 'boolean'
              ? this._createsPaymentSourceDtoPort
                  .createPaymentSource({
                    workspaceId,
                    paymentIntentId: paymentIntent.id,
                  })
                  .pipe(map(res => [res, paymentIntent.id]))
              : throwError(new Error(success)),
          ),
        );
      }),
      tap({
        next: ([res, paymentIntentId]: [Pick<CardDto, 'id' | 'lastDigits' | 'brand'>, string]) => {
          const newCard: CardQuery = CardQuery.fromDto({
            ...res,
            name: command.cardOwner,
            expiryMonth: command.expiryMonth,
            expiryYear: command.expiryYear,
          });
          const updatedCardList = [...this._cardList$.getValue(), newCard];
          this._cardList$.next(updatedCardList);
          this._selectedCardId$.next(updatedCardList.at(0).id);
          this._alert.open('success', 'cobiro_pro_settings_add_payment_method_succeed');
        },
        error: (e: Error) => this._alert.open('error', e.message),
      }),
      map(() => true),
      catchError((e: Error) => {
        let failedFieldNames: string;
        const validationFieldsConfig: [string, string[]][] = [
          ['cvv', ['cvv', 'cvc']],
          ['cardNumber', ['card']],
        ];
        validationFieldsConfig.forEach((validationFieldConfig: [string, string[]]) => {
          const searchExp = new RegExp(
            '\\b(?:' + validationFieldConfig[1].join('|') + ')\\b',
            'gi',
          );
          if (searchExp.test(e.message.toLowerCase())) {
            failedFieldNames = validationFieldConfig[0];
          }
        });
        this._errorFields$.next(failedFieldNames);
        return of(false);
      }),
    );
  }

  private _createPaymentIntent(workspaceId: string): Observable<PaymentIntent> {
    return this._createPaymentIntentDto.createPaymentIntent(workspaceId).pipe(
      map((paymentIntentDto: PaymentIntentDto) => ({
        id: paymentIntentDto.id,
        status: paymentIntentDto.status,
        amount: paymentIntentDto.amount,
        currencyCode: paymentIntentDto.currencyCode,
        gatewayAccountId: paymentIntentDto.gatewayAccountId,
        gateway: paymentIntentDto.gateway,
      })),
    );
  }

  private _mapPlanDtoToPlanOptionQuery(
    plans: PaymentPlanDto[],
    isBundlePrice: boolean = false,
  ): PaymentPlanOptionQuery[] {
    return plans.reduce((acc: PaymentPlanOptionQuery[], planDto: PaymentPlanDto) => {
      Object.keys(PaymentPlanPeriod).forEach((period: string) =>
        acc.push(PaymentPlanOptionQuery.fromDto(planDto, PaymentPlanPeriod[period], isBundlePrice)),
      );
      return acc;
    }, []);
  }

  private _getSiteId(): string {
    return String(this._storage.getItem('cobiro-pro-current-client'));
  }
}
