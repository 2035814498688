<form class="cs-form w-100 h-100" [formGroup]="clientForm">
  <div class="d-flex flex-column">
    <div class="cs-mb-25">
      <label class="cs-label text-transform-none">
        {{ 'cobiro_pro_shop_form_shop_name' | language }}
        <span class="cs-c-error">{{ '_required' | language }}</span>
      </label>
      <input
        formControlName="companyName"
        class="cs-input"
        data-selector="cobiro-pro-new-client-name-input"
        type="text"
        [placeholder]="'cobiro_pro_rewrite_shop_form_shop_name_placeholder' | language"
      />
      <p
        *ngIf="clientForm.controls.companyName.invalid && clientForm.controls.companyName.touched"
        data-selector="cobiro-pro-new-client-name-validation-message"
        class="cs-control-error"
      >
        {{ '_this_field_is_required' | language }}
      </p>
    </div>

    <div class="cs-mb-25">
      <label class="cs-label text-transform-none">
        {{ 'cobiro_pro_client_form_url' | language }}
        <span class="cs-c-error">{{ '_required' | language }}</span>
      </label>
      <input
        data-selector="create-client-url-input"
        formControlName="url"
        class="cs-input"
        type="text"
        [placeholder]="'cobiro_pro_rewrite_client_form_url_placeholder' | language"
      />
      <p
        *ngIf="
          clientForm.controls.url.invalid &&
          clientForm.controls.url.touched &&
          clientForm.controls.url.hasError('maxlength')
        "
        class="cs-control-error"
        data-selector="cobiro-pro-new-client-url-invalid-url-validation-message"
      >
        {{ '_invalid_url' | language }}
      </p>
      <p
        *ngIf="
          clientForm.controls.url.invalid &&
          clientForm.controls.url.touched &&
          clientForm.controls.url.hasError('required')
        "
        class="cs-control-error"
        data-selector="cobiro-pro-new-client-url-invalid-required-validation-message"
      >
        {{ '_this_field_is_required' | language }}
      </p>
    </div>
    <ng-container *ngIf="globalCreation">
      <div *ngIf="workspaces$ | async as workspaces" class="cs-control-wrapper cs-mb-20">
        <label class="cs-label text-transform-none">
          {{ 'cobiro_pro_workspace' | language }}
          <span class="cs-c-error">{{ '_required' | language }}</span>
        </label>
        <mat-form-field class="cs-dropdown">
          <mat-select
            data-selector="existing-workspace-select"
            disableRipple="true"
            formControlName="workspaceId"
            id="workspace-name"
            panelClass="cs-dropdown-options"
          >
            <mat-option
              *ngFor="let workspace of workspaces"
              [value]="workspace.id"
              data-selector="existing-workspace-option"
            >
              {{ workspace.name | language }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </ng-container>
  </div>
</form>
