import { ApplicationEventsHandler, ongoingEventsOrchestrationStrategy } from '@cobiro/eda';
import { Inject, Injectable } from '@angular/core';
import { CobiroProWorkspaceCreatedEvent } from '@app.cobiro.com/core/events';
import { take } from 'rxjs';
import {
  CREATES_WORKSPACE_COMMAND,
  CreatesWorkspaceCommandPort,
} from '../../../application/ports/primary/creates-workspace.command-port';

@Injectable()
export class CobiroProWorkspaceCreatedEventHandler implements ApplicationEventsHandler {
  eventsClasses = [CobiroProWorkspaceCreatedEvent];
  strategy = ongoingEventsOrchestrationStrategy;

  constructor(
    @Inject(CREATES_WORKSPACE_COMMAND)
    private readonly _createsWorkspaceCommandPort: CreatesWorkspaceCommandPort,
  ) {}

  handle([event]: [CobiroProWorkspaceCreatedEvent]): void {
    this._createsWorkspaceCommandPort
      .createWorkspace({ route: event.route, shouldBeRedirected: event.shouldBeRedirected })
      .pipe(take(1))
      .subscribe();
  }
}
