<div
  *ngIf="workspace$ | async as workspace"
  class="position-relative d-flex flex-column align-items-center cs-bg-shade-0 h-100 cs-ph-40 cs-pb-40"
  data-selector="add-user-modal-wrapper"
>
  <mat-icon
    svgIcon="close"
    class="position-relative cs-top-10 cs-left-25 cs-cursor-pointer cs-z-index-1 align-self-end"
    data-selector="cobiro-pro-invite-user-close-modal"
    matDialogClose
  ></mat-icon>

  <div class="cs-emoji-circle cs-emoji-circle-primary cs-mt-5">📨</div>

  <h4 class="cs-title-4 cs-mt-20">{{ 'cobiro_pro_users_add_user' | language }}</h4>
  <p class="cs-text-1 text-center cs-mt-10">
    {{ 'cobiro_pro_users_add_user_description' | language : workspace.name }}
  </p>
  <ng-container *ngIf="hideTabs$ | async; else newUser">
    <mat-tab-group class="w-100">
      <mat-tab>
        <ng-template mat-tab-label>
          <span data-selector="invite-existing-user">{{
            'cobiro_pro_users_current_users' | language
          }}</span>
        </ng-template>
        <ng-container *ngTemplateOutlet="currentUser"></ng-container>
      </mat-tab>
      <mat-tab data-selector="invite-new-user">
        <ng-template mat-tab-label>
          <span data-selector="invite-new-user">{{ 'cobiro_pro_users_new_user' | language }}</span>
        </ng-template>
        <ng-container *ngTemplateOutlet="newUser"></ng-container>
      </mat-tab>
    </mat-tab-group>
  </ng-container>
  <ng-template #currentUser>
    <form
      class="d-flex flex-column justify-content-between w-100 cs-mt-20"
      data-selector="invite-existing-user-form"
      [formGroup]="currentUserForm"
    >
      <div *ngIf="users$ | async as users" class="cs-control-wrapper cs-mb-20">
        <mat-form-field class="cs-dropdown">
          <mat-label>{{ 'cobiro_pro_users_choose_user' | language }}</mat-label>
          <mat-select
            data-selector="existing-user-select"
            disableRipple="true"
            formControlName="userId"
            id="user-email"
            panelClass="cs-dropdown-options"
          >
            <mat-option
              *ngFor="let user of users"
              [value]="user.id"
              data-selector="existing-user-option"
            >
              {{ user.email | language }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <p
          *ngIf="
            currentUserForm.get('userId').invalid &&
            currentUserForm.get('userId').touched &&
            currentUserForm.get('userId').hasError('required')
          "
          class="cs-control-error"
          data-selector="user-email-required-message"
        >
          {{ '_this_field_is_required' | language }}
        </p>
      </div>

      <div class="cs-control-wrapper cs-mt-5">
        <textarea
          *ngIf="false"
          formControlName="comment"
          class="cs-textarea cs-min-height-120-xs"
          [placeholder]="'cobiro_pro_users_optional_message' | language"
        ></textarea>
      </div>

      <button
        class="cs-btn cs-btn-primary cs-mt-5"
        data-selector="cbr-pro-invite-user-submit-btn"
        type="button"
        (click)="onInviteBtnClickedCurrentUser()"
      >
        {{ 'cobiro_pro_users_invite' | language }}
      </button>
    </form>
  </ng-template>

  <ng-template #newUser>
    <form
      class="d-flex flex-column justify-content-between w-100 cs-mt-20"
      data-selector="invite-new-user-form"
      [formGroup]="newUserForm"
    >
      <div class="cs-control-wrapper cs-mb-25">
        <input
          data-selector="cbr-pro-user-invite-email-input"
          class="cs-input"
          type="text"
          formControlName="email"
          [placeholder]="'_email_only' | language"
        />
        <p
          class="cs-control-error"
          data-selector="cobiro-pro-invite-user-required-error"
          *ngIf="
            newUserForm.get('email').invalid &&
            newUserForm.get('email').touched &&
            newUserForm.get('email').hasError('required')
          "
        >
          {{ '_field_is_required' | language }}
        </p>

        <p
          class="cs-control-error"
          data-selector="cobiro-pro-invite-user-pattern-error"
          *ngIf="newUserForm.get('email').hasError('pattern')"
        >
          {{ 'cbr_pro_email_input_error' | language }}
        </p>

        <p
          class="cs-control-error"
          data-selector="cobiro-pro-invite-user-email-taken-error"
          *ngIf="newUserForm.get('email').hasError('emailAlreadyExists')"
        >
          {{ '_email_address_already_taken' | language }}
        </p>
      </div>

      <div class="cs-control-wrapper cs-mt-5">
        <textarea
          *ngIf="false"
          formControlName="comment"
          class="cs-textarea cs-min-height-120-xs"
          [placeholder]="'cobiro_pro_users_optional_message' | language"
        ></textarea>
      </div>

      <button
        class="cs-btn cs-btn-primary cs-mt-5"
        data-selector="cbr-pro-invite-user-submit-btn"
        type="button"
        (click)="onInviteBtnClickedNewUser()"
      >
        {{ 'cobiro_pro_users_invite' | language }}
      </button>
    </form>
  </ng-template>
</div>
