<div *ngIf="getTrial$ | async" class="d-flex flex-column cs-p-20">
  <div class="cs-card">
    <p class="cs-title-5 cs-mb-20">{{ 'cobiro_pro_clients_table_css' | language }}</p>
    <ng-container *ngIf="subscriptionCss$ | async as subscriptionCss">
      <lib-cobiro-pro-activation-widget
        *ngIf="!subscriptionCss.subscription; else subscriptionCssWidget"
        [productInfo]="cssInfo"
      ></lib-cobiro-pro-activation-widget>
      <ng-template #subscriptionCssWidget>
        <lib-cobiro-pro-subscription-widget
          [productInfo]="cssInfo"
          [subscription]="subscriptionCss.subscription"
          [status]="subscriptionCss.status ? subscriptionCss.status : 'none'"
        ></lib-cobiro-pro-subscription-widget>
      </ng-template>
    </ng-container>
  </div>
  <div class="cs-card cs-mt-20">
    <p class="cs-title-5 cs-mb-20">{{ 'cobiro_pro_clients_table_label-manager' | language }}</p>
    <ng-container *ngIf="subscriptionLm$ | async as subscriptionLm">
      <lib-cobiro-pro-activation-widget
        *ngIf="!subscriptionLm.subscription; else subscriptionLmWidget"
        [productInfo]="lmInfo"
      ></lib-cobiro-pro-activation-widget>
      <ng-template #subscriptionLmWidget>
        <lib-cobiro-pro-subscription-widget
          [productInfo]="lmInfo"
          [subscription]="subscriptionLm.subscription"
          [status]="subscriptionLm.status ? subscriptionLm.status : 'none'"
        ></lib-cobiro-pro-subscription-widget>
      </ng-template>
    </ng-container>
  </div>
</div>
