/* eslint-disable max-lines-per-function */
/* eslint-disable complexity */
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ManageClientClickedEvent } from '@app.cobiro.com/cobiro-pro-rewrite/clients';
import {
  TEAM_ID_GETTER,
  TeamIdGetter,
  WORKSPACE_ID_GETTER,
  WorkspaceIdGetter,
} from '@app.cobiro.com/cobiro-pro/context';
import {
  APPLICATION_BUS,
  ApplicationEvent,
  ApplicationEventsHandler,
  Dispatcher,
  ongoingEventsOrchestrationStrategy,
} from '@cobiro/eda';
import { CobiroProWorkspaceSwitchedEvent } from '@app.cobiro.com/core/events';

@Injectable()
export class ManageClientClickedEventHandler implements ApplicationEventsHandler {
  eventsClasses = [ManageClientClickedEvent];
  strategy = ongoingEventsOrchestrationStrategy;

  constructor(
    @Inject(WORKSPACE_ID_GETTER)
    private readonly _workspaceIdGetter: WorkspaceIdGetter,
    @Inject(TEAM_ID_GETTER)
    private readonly _teamIdGetter: TeamIdGetter,
    @Inject(APPLICATION_BUS)
    private readonly _applicationBus: Dispatcher<ApplicationEvent>,
    private readonly _router: Router,
  ) {}

  async handle([event]: [ManageClientClickedEvent]): Promise<void> {
    if (this._workspaceIdGetter.getWorkspaceId() !== event.workspaceId) {
      const workspace = event.workspaceList.find(item => item.id === event.workspaceId);
      this._applicationBus.dispatch(
        new CobiroProWorkspaceSwitchedEvent(
          workspace.id,
          workspace.name,
          workspace.avatar,
          workspace.membersCount,
          workspace.clientCount,
        ),
      );
    }

    this._router.navigate([
      '/',
      'connect',
      this._teamIdGetter.getTeamId(),
      'shops',
      event.clientId,
      event.productName,
    ]);
  }
}
