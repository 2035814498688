import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { SharedAnalyticsModule } from '@app.cobiro.com/shared/analytics';
import { HuiCardExpireDateModule } from '@app.cobiro.com/shared/hui/expire-date/hui-card-expire-date.module';
import { HuiLoadingModule } from '@app.cobiro.com/shared/hui/loading';
import { LanguageModule } from '@app.cobiro.com/common/language';
// import { ChargebeeJsAngularWrapperModule } from '@chargebee/chargebee-js-angular-wrapper';
import { FeatureFlagsModule } from '@cobiro/ng-feature-flags';
import { NgxMaskModule } from 'ngx-mask';
import { CardsModule } from '../../../cards/cards.module';
import { PlanSelectionStepComponent } from './plan-selection-step/plan-selection-step.component';
import { MatIconModule } from '@angular/material/icon';
import { HuiInputModule } from '@app.cobiro.com/shared/hui/input';
import { DiscountCodeComponentModule } from './discout-code/discount-code-component.module';
import { PaymentSummaryComponentModule } from './payment-summary/payment-summary-component.module';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    LanguageModule,
    // ChargebeeJsAngularWrapperModule,
    FeatureFlagsModule,
    MatAutocompleteModule,
    NgxMaskModule.forRoot(),
    HuiLoadingModule,
    CardsModule,
    HuiCardExpireDateModule,
    SharedAnalyticsModule,
    MatIconModule,
    FormsModule,
    HuiInputModule,
    DiscountCodeComponentModule,
    PaymentSummaryComponentModule,
  ],
  declarations: [PlanSelectionStepComponent],
  exports: [PlanSelectionStepComponent],
})
export class UpgradeFlowStepsModule {}
