import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CreateNewClientFormComponent } from './create-new-client-form.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { LanguageModule } from '@app.cobiro.com/common/language';
import { MatSelectModule } from '@angular/material/select';

@NgModule({
  declarations: [CreateNewClientFormComponent],
  imports: [CommonModule, LanguageModule, MatFormFieldModule, MatSelectModule, ReactiveFormsModule],
  exports: [CreateNewClientFormComponent],
})
export class CreateNewClientFormComponentModule {}
