<div
  *ngIf="isLoading$ | async"
  class="loader-container d-flex flex-column justify-content-center align-items-center cs-position-absolute-xs cs-top-0 cs-bottom-0 w-100 h-100"
>
  <p [huiLoading]="true"></p>
  <p
    class="cs-text-1 cs-mt-40 cs-mb-40 text-center"
    *ngIf="loaderMessage$ | async as loaderMessage"
    [innerHtml]="loaderMessage | language"
  ></p>
</div>
<h4 class="align-self-left cs-title-4 cs-mb-10">
  {{ '_results' | language }}
</h4>
<div
  class="cs-title-7 cs-bg-primary-70 cs-c-shade-0 br-10 cs-mb-10 d-flex justify-content-center align-items-center w-100 cs-p-10"
  *ngIf="(totalProducts$ | async) === null"
>
  {{ 'cobiro_pro_label_manager_empty_message' | language }}
</div>
<div
  class="cs-title-7 cs-bg-error-70 cs-c-shade-0 br-10 cs-mb-10 d-flex justify-content-center align-items-center w-100 cs-p-10"
  *ngIf="errorMessage$ | async as errorMessage"
  [innerHTML]="errorMessage.message | language"
></div>
<div class="d-flex flex-column flex-sm-row">
  <div
    class="d-flex flex-column flex-1 cs-bg-shade-0 cs-b-1 cs-b-shade-10 br-10 cs-p-15 cs-p-20-md cs-mb-10 cs-mr-10-sm align-items-left"
  >
    <div class="d-flex flex-row">
      <p class="cs-text-1 flex-1">{{ 'cobiro_pro_label_manager_total_products' | language }}</p>
      <mat-icon class="align-self-end cs-c-shade-100" svgIcon="checklist"></mat-icon>
    </div>
    <h1 class="cs-title-3">
      {{ (totalProducts$ | async) === null ? '-' : (totalProducts$ | async) }}
    </h1>
  </div>
  <div
    class="d-flex flex-column flex-1 cs-bg-shade-0 cs-b-1 cs-b-shade-10 br-10 cs-p-15 cs-p-20-md cs-mb-10 align-items-left"
  >
    <div class="d-flex flex-row">
      <p class="cs-text-1 flex-1">{{ 'cobiro_pro_label_manager_last_update' | language }}</p>
      <mat-icon class="align-self-end cs-c-shade-100" svgIcon="calendar-pick"></mat-icon>
    </div>
    <div class="cs-control-wrapper">
      <select
        (change)="onDateChanged($event.target.value)"
        class="cs-input cs-title-3 cs-height-50-xs cs-select-arrow custom-label-select"
      >
        <option class="cs-text-1" *ngFor="let label of dates$ | async" [value]="label">
          {{ label ? (label | date : 'd/M/yyyy H:mm') : '-' }}
        </option>
      </select>
    </div>
  </div>
</div>
<div class="d-flex justify-content-end">
  <button class="cs-btn cs-btn-stroked-squared" (click)="loadData()" type="button">
    <mat-icon svgIcon="reload"></mat-icon>
  </button>
</div>
<div class="cs-min-height-365 justify-content-center align-items-center">
  <div id="chart" class="w-100" #chartContainer>
    <apx-chart
      data-selector="label-manager-chart-result"
      #chart
      [series]="chartOptions.series"
      [grid]="chartOptions.grid"
      [tooltip]="chartOptions.tooltip"
      [chart]="chartOptions.chart"
      [dataLabels]="chartOptions.dataLabels"
      [plotOptions]="chartOptions.plotOptions"
      [xaxis]="chartOptions.xaxis"
      [yaxis]="chartOptions.yaxis"
      [legend]="chartOptions.legend"
      [fill]="chartOptions.fill"
      [noData]="chartOptions.noData"
      [states]="chartOptions.states"
    ></apx-chart>
  </div>
  <div
    *ngIf="comparisonData$ | async as comparisonData"
    class="d-flex flex-row w-100 text-center cs-ph-10"
  >
    <div class="position-absolute">
      <mat-icon
        class="cs-c-primary cs-cursor-pointer"
        svgIcon="info"
        matTooltipClass="cs-mat-tooltip"
        [matTooltip]="'cobiro_pro_comparison_tooltip' | language"
      ></mat-icon>
    </div>
    <div *ngFor="let data of comparisonData" class="flex-1 d-flex flex-column">
      <span [ngClass]="isPositiveResult(data) ? 'cs-c-success' : 'cs-c-error'">{{
        data.value
      }}</span>
      <span [ngClass]="isPositiveResult(data) ? 'cs-c-success' : 'cs-c-error'" class="cs-text-3">{{
        data.percentage
      }}</span>
    </div>
  </div>
</div>
