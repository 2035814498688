/* eslint-disable complexity */
/* eslint-disable max-lines-per-function */
import { ChangeDetectionStrategy, Component, Inject, OnDestroy } from '@angular/core';
import {
  GetsCurrentClientQueryPort,
  GETS_CURRENT_CLIENT_QUERY,
} from '../../../../../../application/ports/primary/setup/gets-current-client.query-port';
import { delay, firstValueFrom, map, Subject, switchMap, take, takeUntil } from 'rxjs';
import {
  CreatesNewSubscriptionCommandPort,
  CREATES_NEW_SUBSCRIPTION_COMMAND,
} from '../../../../../../application/ports/primary/payments/creates-new-subscription.command-port';
import { ClientDTO } from '../../../../../../application/ports/secondary/dto/clients/client.dto';
import { ActivatedRoute, Router } from '@angular/router';
import {
  IS_TRIAL_PERIOD_QUERY,
  IsTrialPeriodQueryPort,
} from '../../../../../../application/ports/primary/setup/is-trial-period.query-port';
import {
  GETS_CURRENT_PRODUCT_QUERY,
  GetsCurrentProductQueryPort,
} from '../../../../../../application/ports/primary/setup/gets-current-product.query-port';
import { FeatureFlagsState } from '@cobiro/ng-feature-flags';

@Component({
  selector: 'lib-cobiro-pro-confirm-payment',
  templateUrl: './confirm-payment.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmPaymentComponent implements OnDestroy {
  buttonLoading = false;
  private _ngDestroy$ = new Subject<void>();

  isTrial$ = this._getsCurrentProductQueryPort.getCurrentProduct().pipe(
    switchMap(productName => this._isTrialPeriodQueryPort.isTrial(productName)),
    map(res => ({ value: res })),
  );

  constructor(
    @Inject(GETS_CURRENT_PRODUCT_QUERY)
    private readonly _getsCurrentProductQueryPort: GetsCurrentProductQueryPort,
    @Inject(IS_TRIAL_PERIOD_QUERY)
    private readonly _isTrialPeriodQueryPort: IsTrialPeriodQueryPort,
    @Inject(CREATES_NEW_SUBSCRIPTION_COMMAND)
    private readonly _createsNewSubscription: CreatesNewSubscriptionCommandPort,
    @Inject(GETS_CURRENT_CLIENT_QUERY)
    private readonly _getsCurrentClientQueryPort: GetsCurrentClientQueryPort,
    private readonly _router: Router,
    private readonly _route: ActivatedRoute,
    private readonly _featureFlagsState: FeatureFlagsState,
  ) {}

  async onCreateSubscription(isTrial: boolean): Promise<void> {
    this.buttonLoading = true;
    const product = await firstValueFrom(this._getsCurrentProductQueryPort.getCurrentProduct());
    const currentClient = await firstValueFrom(this._getsCurrentClientQueryPort.getCurrentClient());
    const featureFlag = await firstValueFrom(
      this._featureFlagsState.hasFlags(['shops_-_redirect_after_subscription_purchase']),
    );

    await firstValueFrom(
      this._createsNewSubscription
        .createNewSubscription({
          companyName: currentClient.companyName,
          siteId: currentClient.siteId,
          isTrial: isTrial,
        })
        .pipe(delay(3000)),
    );
    if (featureFlag && product && currentClient) {
      this._router.navigate([`../${currentClient.id}/${product}`], { relativeTo: this._route });
    } else {
      this._router.navigate(['../'], { relativeTo: this._route });
    }
  }

  ngOnDestroy(): void {
    this._ngDestroy$.next();
    this._ngDestroy$.complete();
  }
}
