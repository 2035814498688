import { Component, Inject, ChangeDetectionStrategy, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, EMPTY, Subject, tap, takeUntil, firstValueFrom } from 'rxjs';
import {
  GetsInstalledAppsQueryPort,
  GETS_INSTALLED_APPS_QUERY_PORT,
} from '../../../../application/ports/primary/gets-installed_apps.query-port';
import {
  IsStartPageShownQueryPort,
  IS_START_PAGE_SHOWN_QUERY,
} from '../../../../application/ports/primary/is-start-page-shown.query-port';
import {
  StartsIntegrationProcessCommandPort,
  STARTS_INTEGRATION_PROCESS_COMMAND,
} from '../../../../application/ports/primary/starts-integration-process.command-port';
import {
  GETS_TRIAL_PERIOD_QUERY,
  GetsTrialPeriodQueryPort,
} from '../../../../application/ports/primary/gets-trial-period.query-port';

@Component({
  selector: 'lib-cobiro-css-dashboard',
  templateUrl: './cobiro-css-dashboard.component.html',
  styleUrls: ['./cobiro-css-dashboard.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CobiroCssDashboardComponent implements OnInit, OnDestroy {
  private _ngDestroy$ = new Subject<void>();
  readonly _showStartPage$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  readonly showStartPage$ = this._isStartPageShownQueryPort.isStartPageShown(
    this._activatedRoute.snapshot.paramMap.get('shopId'),
  );
  readonly apps$ = this._getInstalledAppsQuery.getInstalledApps();

  constructor(
    @Inject(STARTS_INTEGRATION_PROCESS_COMMAND)
    private readonly _startsIntegrationProcessCommandPort: StartsIntegrationProcessCommandPort,
    @Inject(GETS_INSTALLED_APPS_QUERY_PORT)
    private readonly _getInstalledAppsQuery: GetsInstalledAppsQueryPort,
    @Inject(IS_START_PAGE_SHOWN_QUERY)
    private readonly _isStartPageShownQueryPort: IsStartPageShownQueryPort,
    private _activatedRoute: ActivatedRoute,
    @Inject(GETS_TRIAL_PERIOD_QUERY)
    private readonly _getsTrialPeriodQueryPort: GetsTrialPeriodQueryPort,
  ) {}

  async ngOnInit(): Promise<void> {
    await firstValueFrom(this._getsTrialPeriodQueryPort.getTrial());
  }

  ngOnDestroy(): void {
    this._ngDestroy$.next();
    this._ngDestroy$.complete();
  }

  startIntegration(): void {
    const id = this._activatedRoute.snapshot.paramMap.get('shopId');
    this._startsIntegrationProcessCommandPort
      .startIntegration({ clientId: id })
      .pipe(
        tap((res: boolean) => (res ? this._showStartPage$.next(false) : EMPTY)),
        takeUntil(this._ngDestroy$),
      )
      .subscribe();
  }
}
