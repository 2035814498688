import { NgModule } from '@angular/core';
import { CobiroProTopBarState } from './cobiro-pro-top-bar.state';
import { GETS_COMPANY_CONFIG_QUERY } from '../../ports/primary/gets-company-config.query-port';
import { GETS_WORKSPACE_CONFIG_QUERY } from '../../ports/primary/gets-workspace-config.query-port';
import { SET_CURRENT_TEAM_COMMAND } from '../../ports/primary/set-current-team-command.port';
import { LOAD_TEAMS_COMMAND } from '../../ports/primary/load-teams-command.port';
import { HAS_TEAM_QUERY } from '../../ports/primary/has-team-query.port';
import { SET_CURRENT_WORKSPACE_COMMAND } from '../../ports/primary/set-current-workspace-command.port';
import { CREATE_WORKSPACE_COMMAND } from '../../ports/primary/create-workspace.command-port';
import { TOGGLE_SIDEBAR_COMMAND } from '../../ports/primary/toggle-sidebar.command-port';
import { IS_SIDEBAR_SHOWN_COMMAND } from '../../ports/primary/is-sidebar-shown.command-port';
import { CREATE_TEAM_COMMAND } from '../../ports/primary/create-team.command-port';
import { GETS_SHOP_CONFIG_QUERY } from '../../ports/primary/gets-shop-config.query-port';
import { GETS_ALL_WORKSPACES_QUERY } from '../../ports/primary/gets-all_workspaces.query-port';
import { CREATE_SHOP_COMMAND } from '../../ports/primary/create-shop.command-port';

@NgModule({
  providers: [
    CobiroProTopBarState,
    { provide: GETS_COMPANY_CONFIG_QUERY, useExisting: CobiroProTopBarState },
    { provide: GETS_WORKSPACE_CONFIG_QUERY, useExisting: CobiroProTopBarState },
    { provide: SET_CURRENT_TEAM_COMMAND, useExisting: CobiroProTopBarState },
    { provide: SET_CURRENT_WORKSPACE_COMMAND, useExisting: CobiroProTopBarState },
    { provide: HAS_TEAM_QUERY, useExisting: CobiroProTopBarState },
    { provide: LOAD_TEAMS_COMMAND, useExisting: CobiroProTopBarState },
    { provide: CREATE_WORKSPACE_COMMAND, useExisting: CobiroProTopBarState },
    { provide: IS_SIDEBAR_SHOWN_COMMAND, useExisting: CobiroProTopBarState },
    { provide: TOGGLE_SIDEBAR_COMMAND, useExisting: CobiroProTopBarState },
    { provide: CREATE_TEAM_COMMAND, useExisting: CobiroProTopBarState },
    { provide: GETS_SHOP_CONFIG_QUERY, useExisting: CobiroProTopBarState },
    { provide: GETS_ALL_WORKSPACES_QUERY, useExisting: CobiroProTopBarState },
    { provide: CREATE_SHOP_COMMAND, useExisting: CobiroProTopBarState },
  ],
})
export class CobiroProTopBarStateModule {}
