/* eslint-disable complexity */
/* eslint-disable max-lines-per-function */
import { Injectable } from '@angular/core';
import { GatewayClient } from '@app.cobiro.com/common/gateway';
import { HasDataCollection } from '@cobiro/jsonapi';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { GetsAllTeamClientDtoPort } from '../../../../application/ports/secondary/gets-all-team-client.dto-port';
import { ClientDTO } from '../../../../application/ports/secondary/client.dto';
import { GetsAllTeamClientDto } from '../../../../application/ports/secondary/gets-all-team-client.dto';

export interface ClientAttributes {
  siteId: string;
  sitePublicId: string;
  cssIntegrationStatus: 'paused' | 'active' | 'pending' | null;
  merchantId: string | null;
  teamId: string;
  workspaceId: string;
  companyName: string;
  contactPerson: string;
  contactPhone: string;
  contactEmail: string;
  createdAt?: string;
  url: string;
  avatarUrl: string;
  source: string;
  plan: string;
  archived: boolean;
  cssDomainId: string;
}

@Injectable()
export class HttpClientsService implements GetsAllTeamClientDtoPort {
  constructor(private readonly _gatewayClient: GatewayClient) {}

  getAllTeamClients(dto: GetsAllTeamClientDto): Observable<ClientDTO[]> {
    return this._gatewayClient
      .get<HasDataCollection<ClientAttributes>>(`v1/sites/teams/${dto.teamId}/clients`)
      .pipe(
        map((clients: HasDataCollection<ClientAttributes>) => {
          return clients.data
            .filter(data => !data.attributes.archived)
            .map(data => {
              const siteId = data.attributes.siteId;
              return {
                id: data.id || '',
                siteId: siteId ? String(siteId) : siteId,
                workspaceId: data.attributes.workspaceId,
                teamId: dto.teamId,
                merchantSiteId: data.attributes.merchantId,
                sitePublicId: data.attributes.sitePublicId,
                companyName: data.attributes.companyName,
                contactPerson: data.attributes.contactPerson,
                contactEmail: data.attributes.contactEmail,
                contactPhone: data.attributes.contactPhone,
                createdAt: data.attributes.createdAt,
                cssIntegrationStatus: data.attributes.cssIntegrationStatus,
                plan: data.attributes.plan,
                url: data.attributes.url,
                source: data.attributes.source,
                avatar: data.attributes.avatarUrl,
                installedApps: [],
                archived: data.attributes.archived,
                subscriptions: null,
                productStatuses: {
                  css: data.attributes.cssIntegrationStatus,
                  'label-manager': null,
                },
                cssDomainId: data.attributes.cssDomainId,
              };
            });
        }),
      );
  }
}
