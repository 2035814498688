import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { BehaviorSubject, map } from 'rxjs';
import { CreateClientForm } from './create-new-client-form/create-new-client-form.component';

@Component({
  selector: 'lib-cobiro-pro-create-new-client-modal',
  templateUrl: './create-new-client-modal.component.html',
  styleUrls: ['./create-new-client-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateNewClientModalComponent implements OnDestroy {
  private _isValidForm$ = new BehaviorSubject<boolean>(false);
  private _clientForm$ = new BehaviorSubject<CreateClientForm>(null);
  readonly isInvalid$ = this._isValidForm$.asObservable().pipe(map(res => !res));

  constructor(
    private readonly _matDialogRef: MatDialogRef<CreateNewClientModalComponent>,
    private readonly _router: Router,
    @Inject(MAT_DIALOG_DATA)
    readonly data: { route: ActivatedRoute; globalCreation: boolean },
  ) {}

  onFormGroupChange(form: CreateClientForm): void {
    this._clientForm$.next(form);
  }

  onValidityChange(isValid: boolean): void {
    this._isValidForm$.next(isValid);
  }

  onFormClosed(): void {
    this._matDialogRef.close();
  }

  onFormSubmitted(): void {
    const formValue = this._clientForm$.getValue();
    this._matDialogRef.close(formValue);
  }

  ngOnDestroy(): void {
    if (!this.data.globalCreation) {
      this._router.navigate(['.'], { relativeTo: this.data.route });
    }
  }
}
