/* eslint-disable max-lines-per-function */
/* eslint-disable complexity */
import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { tap, Observable, switchMap, take } from 'rxjs';
import {
  LoadClientsCommandPort,
  LOAD_CLIENTS_COMMAND,
} from '../../../application/ports/primary/clients/load-clients.command-port';
import {
  SetsSelectedClientCommandPort,
  SETS_SELECTED_CLIENT_COMMAND,
} from '../../../application/ports/primary/clients/sets-selected-client-command.port';
import { ClientDTO } from '../../../application/ports/secondary/dto/clients/client.dto';
import { STORAGE } from '@app.cobiro.com/core/storage';
import { TEAM_ID_GETTER, TeamIdGetter } from '@app.cobiro.com/cobiro-pro/context';
import { APPLICATION_BUS, ApplicationEvent, Dispatcher } from '@cobiro/eda';
import { LocalStorageShopsChangedEvent } from '../../../application/events/local-storage-shops-changed.event';

@Injectable()
export class LoadSelectedShopResolver {
  constructor(
    @Inject(STORAGE)
    private readonly _storage: Storage,
    @Inject(TEAM_ID_GETTER)
    private readonly _teamIdGetter: TeamIdGetter,
    @Inject(LOAD_CLIENTS_COMMAND)
    private readonly _loadClientsCommandPort: LoadClientsCommandPort,
    @Inject(SETS_SELECTED_CLIENT_COMMAND)
    private readonly _setSelectedClientCommand: SetsSelectedClientCommandPort,
    @Inject(APPLICATION_BUS)
    private readonly _applicationBus: Dispatcher<ApplicationEvent>,
  ) {}

  resolve(snapshot: ActivatedRouteSnapshot): Observable<ClientDTO> {
    return this._loadClientsCommandPort.loadClients().pipe(
      take(1),
      switchMap((clients: ClientDTO[]) => {
        const selectedClient: ClientDTO = clients.find(
          item => item.id === snapshot.paramMap.get('shopId'),
        );
        const recentlyViewedShops = this._storage.getItem('cobiro-pro-recently-viewed-shops')
          ? JSON.parse(this._storage.getItem('cobiro-pro-recently-viewed-shops'))
          : {};
        if (recentlyViewedShops[this._teamIdGetter.getTeamId()]) {
          const deleteIfExist = recentlyViewedShops[this._teamIdGetter.getTeamId()].filter(
            (e: string) => e !== selectedClient.id,
          );
          deleteIfExist.unshift(selectedClient.id);

          recentlyViewedShops[this._teamIdGetter.getTeamId()] = deleteIfExist.slice(0, 5);
        } else {
          recentlyViewedShops[this._teamIdGetter.getTeamId()] = [selectedClient.id];
        }
        this._storage.setItem(
          'cobiro-pro-recently-viewed-shops',
          JSON.stringify(recentlyViewedShops),
        );
        this._applicationBus.dispatch(new LocalStorageShopsChangedEvent());
        return this._setSelectedClientCommand.setSelectedClient(selectedClient.id);
      }),
    );
  }
}
