import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import {
  GETS_MEMBER_WORKSPACES_LIST_QUERY,
  GetsMemberWorkspacesListQuery,
} from '../../../../../../application/ports/primary/workspaces/gets-member-workspace-list.query-port';
import { distinctUntilChanged, Subject, takeUntil, tap } from 'rxjs';
import { WORKSPACE_ID_GETTER, WorkspaceIdGetter } from '@app.cobiro.com/cobiro-pro/context';

export interface CreateClientForm {
  companyName: string;
  url: string;
  workspaceId: string;
}

@Component({
  selector: 'lib-cobiro-pro-create-new-client-form',
  templateUrl: './create-new-client-form.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateNewClientFormComponent implements OnInit, OnDestroy {
  @Input() globalCreation: boolean;
  @Output() private formGroupChange = new EventEmitter<CreateClientForm>();
  @Output() private validityChanged = new EventEmitter<boolean>();

  workspaces$ = this._getsMemberWorkspacesListQuery.getMemberWorkspaceListQuery();

  private _ngDestroy$ = new Subject<void>();

  readonly clientForm: FormGroup = this._fb.group({
    companyName: new FormControl<string>(null, [Validators.required]),
    url: new FormControl<string>(null, [Validators.required, Validators.maxLength(200)]),
    workspaceId: new FormControl<string>(this._workspaceIdGetter.getWorkspaceId(), [
      Validators.required,
    ]),
  });

  constructor(
    private readonly _fb: FormBuilder,
    @Inject(WORKSPACE_ID_GETTER)
    private readonly _workspaceIdGetter: WorkspaceIdGetter,
    @Inject(GETS_MEMBER_WORKSPACES_LIST_QUERY)
    private readonly _getsMemberWorkspacesListQuery: GetsMemberWorkspacesListQuery,
  ) {}

  ngOnInit(): void {
    this.clientForm.valueChanges.subscribe(() =>
      this.formGroupChange.emit(this.clientForm.getRawValue()),
    );
    this.clientForm.statusChanges
      .pipe(distinctUntilChanged(), takeUntil(this._ngDestroy$))
      .subscribe(res => this.validityChanged.emit(res === 'VALID'));
  }

  ngOnDestroy(): void {
    this._ngDestroy$.next();
    this._ngDestroy$.complete();
  }
}
