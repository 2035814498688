import { Observable } from 'rxjs';
import { InjectionToken } from '@angular/core';
import { GetsAllTeamClientDto } from './gets-all-team-client.dto';
import { ClientDTO } from './client.dto';

export const GETS_ALL_TEAM_CLIENT_DTO = new InjectionToken<GetsAllTeamClientDtoPort>(
  'GETS_ALL_TEAM_CLIENT_DTO',
);

export interface GetsAllTeamClientDtoPort {
  getAllTeamClients(dto: GetsAllTeamClientDto): Observable<ClientDTO[]>;
}
