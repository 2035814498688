<div
  class="cs-width-100-pc-xs cs-width-50-pc-md d-flex flex-column flex-1 cs-card cs-p-40 cs-mb-10 cs-mr-10 align-items-center text-center"
>
  <img class="cs-width-60-xs cs-height-60-xs cs-pb-20" [src]="productInfo.img" />
  <h1 class="cs-title-5">{{ 'cobiro_pro_clients_table_' + productInfo.name | language }}</h1>
  <p class="cs-text-2 text-left cs-mb-20 text-center">
    {{ 'cobiro_pro_shop_overview_' + productInfo.name + '_description' | language }}
  </p>
  <ng-container *ngIf="isTrial$ | async as isTrial">
    <button class="cs-btn cs-btn-primary mt-auto cs-mb-10" (click)="onUpgradePlanClicked()">
      {{
        (isTrial.value ? '_try_it_now' : 'cobiro_pro_shop_overview_' + productInfo.name + '_button')
          | language
      }}
      <mat-icon class="cs-ml-10" svgIcon="arrow-right"></mat-icon>
    </button>
  </ng-container>
</div>
