import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import {
  GETS_PRODUCT_LIST_QUERY,
  GetsProductListQuery,
} from '../../../../application/ports/primary/clients/gets-product-list.query';
import {
  GETS_PRODUCT_INFO_QUERY,
  GetsProductInfoQueryPort,
} from '../../../../application/ports/primary/clients/gets-product-info.query-port';
import { PRODUCT_LIST } from '../../../../application/ports/primary/clients/product-list.query';
import { ActivatedRoute } from '@angular/router';
import { ClientListItemQuery } from '../../../../application/ports/primary/clients/client-list-item.query';
import { distinctUntilChanged, firstValueFrom, map, switchMap, take, tap } from 'rxjs';
import { ProductQuery } from '../../../../application/ports/primary/clients/product.query';
import {
  GETS_TRIAL_PERIOD_QUERY,
  GetsTrialPeriodQueryPort,
} from '../../../../application/ports/primary/setup/gets-trial-period.query-port';
import {
  GETS_SELECTED_CLIENT_QUERY,
  GetsSelectedClientQueryPort,
} from '../../../../application/ports/primary/clients/gets-selected-client.query-port';
import { throwDialogContentAlreadyAttachedError } from '@angular/cdk/dialog';

@Component({
  selector: 'lib-cobiro-pro-client-overview',
  templateUrl: './client-overview.component.html',
  styleUrls: ['./client-overview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClientOverviewComponent implements OnInit {
  readonly products = PRODUCT_LIST;
  cssInfo: ProductQuery;
  lmInfo: ProductQuery;

  private readonly _currentClient$ = this._getSelectedClientQuery.getSelectedClient();
  getTrial$ = this._getsTrialPeriodQueryPort.getTrial().pipe(map(() => true));
  subscriptionLm$ = this._currentClient$.pipe(
    distinctUntilChanged(),
    switchMap(() =>
      this._getsProductInfoQueryPort.getProductInfo({
        product: PRODUCT_LIST.labelManager,
        client: { id: this._route.snapshot.paramMap.get('shopId') } as ClientListItemQuery,
      }),
    ),
  );

  subscriptionCss$ = this._currentClient$.pipe(
    distinctUntilChanged(),
    switchMap(() =>
      this._getsProductInfoQueryPort.getProductInfo({
        product: PRODUCT_LIST.css,
        client: { id: this._route.snapshot.paramMap.get('shopId') } as ClientListItemQuery,
      }),
    ),
  );

  constructor(
    @Inject(GETS_PRODUCT_INFO_QUERY)
    private readonly _getsProductInfoQueryPort: GetsProductInfoQueryPort,
    @Inject(GETS_PRODUCT_LIST_QUERY)
    private readonly _getsProductListQuery: GetsProductListQuery,
    @Inject(GETS_TRIAL_PERIOD_QUERY)
    private readonly _getsTrialPeriodQueryPort: GetsTrialPeriodQueryPort,
    @Inject(GETS_SELECTED_CLIENT_QUERY)
    private readonly _getSelectedClientQuery: GetsSelectedClientQueryPort,
    private readonly _route: ActivatedRoute,
  ) {}

  async ngOnInit(): Promise<void> {
    await firstValueFrom(
      this._getsProductListQuery.getAllProducts().pipe(
        take(1),
        tap(res => {
          this.cssInfo = res.find(item => item.name === PRODUCT_LIST.css);
          this.lmInfo = res.find(item => item.name === PRODUCT_LIST.labelManager);
        }),
      ),
    );
  }
}
