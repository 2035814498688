import { TEAM_ID_GETTER, TeamIdGetter } from './team-id.getter';
import { Provider } from '@angular/core';

export const TEAM_ID_STUB = '3dda2fc0-cd45-49b1-b839-517e9f38f8a9';

export const provideTeamIdGetterStub = (teamId = TEAM_ID_STUB): Provider => ({
  provide: TEAM_ID_GETTER,
  useValue: {
    getTeamId: () => teamId,
  } as TeamIdGetter,
});
