import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfirmPaymentComponent } from './confirm-payment.component';
import { LanguageModule } from '@app.cobiro.com/common/language';
import { CobiroProUpradePlanModule } from '../../../modals/change-payment-plan-modal/upgrade-plan-step/upgrade-plan/upgrade-plan.component-module';
import { MatIconModule } from '@angular/material/icon';
import { FeatureFlagsModule } from '@cobiro/ng-feature-flags';

@NgModule({
  declarations: [ConfirmPaymentComponent],
  imports: [
    CommonModule,
    LanguageModule,
    FeatureFlagsModule,
    MatIconModule,
    CobiroProUpradePlanModule,
  ],
  exports: [ConfirmPaymentComponent],
})
export class ConfirmPaymentComponentModule {}
