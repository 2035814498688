import { WORKSPACE_ID_GETTER, WorkspaceIdGetter } from './workspace-id.getter';
import { Provider } from '@angular/core';

export const WORKSPACE_ID_STUB = '3dda2fc0-cd45-49b1-b839-517e9f38f8a9';

export const provideWorkspaceIdGetterStub = (workspaceId = WORKSPACE_ID_STUB): Provider => ({
  provide: WORKSPACE_ID_GETTER,
  useValue: {
    getWorkspaceId: () => workspaceId,
  } as WorkspaceIdGetter,
});
