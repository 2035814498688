<div
  class="position-relative d-flex flex-column cs-ph-40 cs-pb-40 justify-content-between align-items-center cs-bg-shade-0"
>
  <mat-icon
    svgIcon="close"
    class="position-relative cs-top-10 cs-left-25 cs-cursor-pointer cs-z-index-1 align-self-end"
    data-selector="cobiro-pro-create-client-close-modal"
    (click)="onFormClosed()"
  ></mat-icon>
  <div class="d-flex flex-column w-100 h-100 justify-content-between">
    <div class="d-flex flex-column">
      <h3 class="cs-title-4 cs-mb-15" data-selector="cobiro-pro-create-client-modal-title">
        {{ 'cobiro_pro_create_shop_modal_title' | language }}
      </h3>
    </div>

    <lib-cobiro-pro-create-new-client-form
      class="d-flex flex-column"
      [globalCreation]="data.globalCreation"
      (formGroupChange)="onFormGroupChange($event)"
      (validityChanged)="onValidityChange($event)"
    ></lib-cobiro-pro-create-new-client-form>
  </div>
  <button
    [disabled]="isInvalid$ | async"
    (click)="onFormSubmitted()"
    class="cs-btn cs-btn-primary w-100"
    type="button"
    data-selector="cobiro-pro-create-client-create-btn"
    data-analytics="cobiro-pro-create-client-create-btn"
  >
    {{ 'cobiro_pro_create_shop' | language }}
  </button>
</div>
