import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { DropdownConfigQuery } from './dropdown-config.query';

export const GETS_SHOP_CONFIG_QUERY = new InjectionToken<GetsShopConfigQueryPort>(
  'GETS_SHOP_CONFIG_QUERY',
);

export interface GetsShopConfigQueryPort {
  getShopConfig(): Observable<DropdownConfigQuery>;
}
